import {Anchor, Center, Menu, Stack, UnstyledButton} from "@mantine/core";
import MainMenu, { MainMenuItem } from "./MainMenu";

import SideBarDropDown from "../neoverv/components/Module/General/SidebarNav/components/SideBarDropDown";
import Icon from "./Icon";
import { Link } from "react-router-dom";
import Autoload from "../neoverv/Autoload";
import { useState } from "react";
import StarterBadgeIcon from '../assets/images/module/general/StarterBadgeIcon.svg';
import PremiumBadgeIcon from '../assets/images/module/general/PremiumBadgeIcon.svg';
import ProfessionalBadgeIcon from '../assets/images/module/general/ProfessionalBadgeIcon.svg';
import SubscriptionBadgeIcon from "../neoverv/components/SVGs/SubscriptionBadgeIcon";

const AppSidebar = (props) => {
  const [hover, setHover] = useState(false);
  const handleLogout = () => {
    Autoload.Utils.clear_session_data();
    window.Intercom('shutdown');
    props.resetData();
    window.location.href = "/login";
    props.setShowAfterSignIn(true);
  }
  
  return (
    <Stack 
      spacing="md"
      justify="space-between"
      sx={{ 
        height: '100vh', 
        paddingBottom: 16,
        position: "sticky",
        top: 0,
        bottom: 0
      }}
      style={{overflowY: 'scroll'}}
      className='app-main-menu'
    >

      {/* Main options and menu */}
      <Stack>
        <Stack spacing={10}>
          <Center pt={24}>
            <Anchor href="/"><img src={`/hello-housing-logo.svg`} alt="Hello Housing Logo" /></Anchor>
          </Center>
          {props.currentSubscription &&
          <Center>
            {/* <img style={{cursor: 'pointer'}} onClick={()=> props.jumpToSubscriptions()} src={props.currentSubscription ? props.currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' ? StarterBadgeIcon : props.currentSubscription?.subscriptionKey.split('_')[0] === 'PREMIUM' ? PremiumBadgeIcon : ProfessionalBadgeIcon : StarterBadgeIcon} height={'24px'} /> */}
            <SubscriptionBadgeIcon onClick={()=> props.jumpToSubscriptions()} name={props?.currentSubscription?.subscriptionName} />
          </Center>}
        </Stack>

        <SideBarDropDown
        isTablet={props.isTablet} 
        jumpToSubscriptions={()=> props.jumpToSubscriptions()}
        currentSubscription={props.currentSubscription}
        skeletonPropertyDropdown={props.skeletonPropertyDropdown}
        blockUsersFromUsingApp={props.blockUsersFromUsingApp}
        history={props.history}
        hasTested={props.hasTested} />

        <MainMenu hasTested={props.hasTested} accountData={props.accountData} propertySelected={props.propertySelected} history={props.history} blockUsersFromUsingApp={props.blockUsersFromUsingApp}/>
      </Stack>
      {/* User area */}
      <Stack spacing={6} style={{marginTop: '10px'}} >
        {/* <MainMenuItem icon="partner" route="/partner" blockUsersFromUsingApp={false}>Partner</MainMenuItem> */}
        <MainMenuItem icon="help" blockUsersFromUsingApp={false}//href="https://wissen.hellohousing.de/" target="_blank"
        onClick={()=> window.Intercom('show')}
        >Hilfe</MainMenuItem>
        {/* <Menu
          size="xl"
          shadow="xs"
          control={<UnstyledButton sx={{ width: "100%", fontFamily: 'Inter,sans-serif', fontWeight: '500' }}><MainMenuItem route="/profile" icon="user">Konto</MainMenuItem></UnstyledButton>}
          position="top"
          placement="start"
          trigger="hover"
          styles={{
            body: {
              transform: "translateX(6px)"
            }
          }}
        >
          <Menu.Item 
          className="link-to-profile"
          sx={{color: hover ? 'rgba(86, 85, 255, 1.00)' : 'rgba(154, 154, 181, 1.00)', textDecoration: 'none'}} 
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)} 
          component={Link} to="/profile" icon={<Icon name="user" color={hover ? 'rgba(86, 85, 255, 1.00)' : 'rgba(154, 154, 181, 1.00)'} />}>Profil</Menu.Item>
          <Menu.Item onClick={handleLogout} icon={<Icon name="logout" color="#dd0000" />} color="red">Abmelden</Menu.Item>
        </Menu> */}
        <MainMenuItem icon="user" route="/profile" blockUsersFromUsingApp={false}>Konto</MainMenuItem>
        <MainMenuItem icon="logout" color={'red'} blockUsersFromUsingApp={false} onClick={handleLogout}>Abmelden</MainMenuItem>
      </Stack>
      
    </Stack>
  )
}

export default AppSidebar