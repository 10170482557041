import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import ViewUtilityBilling from './ViewUtilityBilling.js';
import UtilityHeaders from './UtilityHeaders';
import { withRouter } from 'react-router-dom';
import Autoload from '../../../../neoverv/Autoload.js';
import Setting from '../../../../neoverv/config/Setting.js';
import moment from 'moment';
import { getRentalUnitTenants } from '../../../../redux/action/Tenant/TenantActions.js';
import { connect } from 'react-redux';
import { setUtilityBillingData } from '../../../../redux/action/UtilityBilling/UtilityBillingActions.js';
import { Box, Group, Overlay, Stack } from '@mantine/core';
import dayjs from 'dayjs';

const MOBILE_SIZE = 600;

var lang = Setting[Setting.language_default];
const {
  filterBySearchBarRentalTenants,
  deleteRecord,
  checkProperty,
  mergeArrayByProperty,
  nestedAlphabeticSort,
  filterTenantsAndUnits,
  filterByDateUtility,
} = Autoload.MainHelper;
let rentalUnits = [];
class List extends Component {
  state={
    isMobile: window.innerWidth < MOBILE_SIZE
  }
  componentDidMount() {
    this.props.propertySelected && this.props.propertySelected.id !== undefined && this.bind_rental_unit_list_data();
    window.addEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize);
  }

  bind_rental_unit_list_data = () => {
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    // console.log('property id', propertyid,  Autoload.Setting.rental_unit_api_base_url+"?propertyId="+propertyid)
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalUnits?offset=0&fetch=100&propertyId=' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_list_rental_unit',
      null,
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handle_get_list_rental_unit = (response) => {
    if (response.result === null || response.result && response.result.length === 0) {
      // this.ShowLoader();
    }
    console.log(response, 'add tenant response');
    this.props.getRentalUnitTenants([]);
    if (response.status === false) {
      // Autoload.Redirection.redirection_error();
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(
      //   true,
      //   lang.newKeys.text_error_not_found
      //   // + response.message
      // );
    }else{
      rentalUnits = response.result.map((ru) => ({
        id: checkProperty(ru, 'id'),
        name: checkProperty(ru, 'name'),
        size: checkProperty(ru, 'space'),
        propertyId: checkProperty(ru, 'propertyId'),
        tenantsData: [],
      }));
      // console.log('rentalUnits global', rentalUnits);
      response.result.map((ru) => {
        // console.log('ru', ru)
        Autoload.HttpRequestAxios.HttpRequest(
          Autoload.Setting.property_api_base_url + 'tenants?offset=0&fetch=100&rentalUnitId=' + ru.id,
          'GET',
          null,
          null,
          false,
          true,
          false,
          this,
          'handle_get_list_rental_tenants',
          null,
          false,
          '',
          {
            //'Content-Type': 'application/json',
            Authorization: Autoload.Utils.getAuthToken(),
          }
        );
      });
    }
  };
  handle_get_list_rental_tenants = (response) => {
    // this.ShowLoader();
    // console.log('last api function  handle_get_list_rental_tenants', response);
    // rentalListData = this.props.rentalUnits;

    if (response.result.length > 0) {
      // let tenantsData = response.result.sort(function (a, b) {
      //   return new Date(a.moveInDate) - new Date(b.moveInDate);
      // });
      //sort for move out
      let dashMoveOut = response.result.filter((item) => item.out === '');
      let filledMovedout = response.result.filter((item) => item.out !== '');
      filledMovedout.map((item) => dashMoveOut.push(item));
      //let current = false;
      let tenantsData = [...dashMoveOut];
      // first vacant false check
      let isFirstVacantFound = false;
      //first vancant false = index
      let firstOriginalDataIndex;

      tenantsData = response.result.map((tenant, idx) => {
        console.log(tenant, idx, 'tenant current idx');
        // isVacant value
        let isVacant = tenant.vacant;
        let current = false;

        //console.log(dateCompare, moment(checkProperty(tenant, 'moveOutDate')).format('DD.MM.YYYY'),'dateCompare')
        // if(dateCompare==false&&response.result.moveOutDate!=='-'){
        if (
          (dayjs(tenant.moveInDate).isBefore(dayjs()) || dayjs(tenant.moveInDate).isSame(dayjs())) //logic for moveInDate
          && 
          (tenant.moveOutDate === null || dayjs(tenant.moveOutDate).isAfter(dayjs()) || dayjs(tenant.moveOutDate).isSame(dayjs())) //logic for moveOutDate
        ) {
          //
          firstOriginalDataIndex = idx;
          current = true;
          isFirstVacantFound = true;
        }else if(tenant.vacant && tenant.moveInDate === null && tenant.moveOutDate === null && response.result.length === 1){
          current = true;
        }

        return {
          id: checkProperty(tenant, 'id'),
          name: checkProperty(tenant, 'fullName'),
          email: checkProperty(tenant, 'email'),
          phone: checkProperty(tenant, 'phone'),
          in: moment(checkProperty(tenant, 'moveInDate')).format('DD.MM.YYYY'),
          out: checkProperty(tenant, 'moveOutDate')
            ? moment(checkProperty(tenant, 'moveOutDate')).format('DD.MM.YYYY')
            : '',
          type: checkProperty(tenant, 'contractType'),
          note: checkProperty(tenant, 'notes'),
          current: current,
          createdOn: checkProperty(tenant, 'createdOn'),
          vacant: checkProperty(tenant, 'vacant'),
          // rentalUnit: tenant.rentalUnit,
          rentalUnitId: checkProperty(tenant, 'rentalUnitId'),
          updatedOn: checkProperty(tenant, 'updatedOn'),
          numberOfOccupants: tenant.numberOfOccupants
        };
      });
      console.log(tenantsData, '======tenantsData');
      if (tenantsData.length === 1 && tenantsData[0].vacant == true) {
        //tenantsData[0].current = true;

        // let moveoutDate=tenantsData[0].out;
        // let dateCompare = moment(moment(moveoutDate).format('DD.MM.YYYY')).isSameOrAfter(moment(new Date()).format('DD.MM.YYYY'));
        // console.log(moveoutDate,dateCompare,tenantsData[0],'====abc======')
        // if(dateCompare===true){
        //   tenantsData[0].current = true;
        // }
      } else if (firstOriginalDataIndex !== 0 && firstOriginalDataIndex !== undefined) {
        // console.log('abc', firstOriginalDataIndex, tenantsData[1]);
        // tenantsData[firstOriginalDataIndex - 1].current = true;
        let moveoutDate = tenantsData[firstOriginalDataIndex].out;
        let dateCompare = moment(this.ChangeDateFormat(moveoutDate)).isSameOrAfter(moment(new Date()));
        console.log(moveoutDate, dateCompare, tenantsData[firstOriginalDataIndex], '====abc======');
        if (dateCompare === false) {
          //tenantsData[firstOriginalDataIndex - 1].current = true;
          tenantsData[firstOriginalDataIndex].current = false;
        } else {
          //tenantsData[firstOriginalDataIndex - 1].current = true;
        }
      }

      //action
      //row ko array
      let field = 'name';
      let rentalunitsorted = rentalUnits.sort((a, b) =>
        (a[field] || '').toString().localeCompare((b[field] || '').toString())
      );
      rentalUnits = rentalunitsorted.map((ru) => {
        if (ru.id == tenantsData[0].rentalUnitId) {
          //  console.log('worked', tenantsData)
          return {
            ...ru,
            tenantsData: [...tenantsData],
          };
        } else {
          return ru;
        }
      });

      console.log('tenant of rental unit', tenantsData, rentalUnits);
    }

    // console.log('data formated', data);
    //   rentalListData.push(data);
    //   let sorted=  rentalListData.sort(function(a, b) {
    //     return a.name.localeCompare(b.name);
    //  });
    // console.log('global array', rentalListData, sorted )
    // const sortedData = nestedAlphabeticSort(rentalListData, 'name', true, null);
    //pass data received to redux

    this.props.getRentalUnitTenants(rentalUnits);
  };
  ChangeDateFormat = (dateString) => {
    const dateArray = dateString.split('.');
    return `${dateArray[1]}.${dateArray[0]}.${dateArray[2]}`;
  };

  render() {
    const {isMobile} = this.state;
    console.log(this.props.match.url);
    return (
      <div className="utilityClass" style={{overflowX: 'hidden', overflowY: 'scroll', paddingTop: '10px'}}>
        <Box sx={{ height: isMobile && '100vh', position: 'relative', padding: isMobile && 10}}>
            <Stack style={{zIndex: 1}}>
              <div>
                <Route path={this.props.match.url} exact component={UtilityHeaders} />
                <Route path={this.props.match.url + '/utility'} exact component={ViewUtilityBilling} />
              </div>
            </Stack>
            {isMobile &&
            <Stack align={'center'} style={{ justifyContent: 'center', zIndex: 50, position: 'absolute', top: 200, left: 0, right: 0, top: 0, bottom: 0, margin: 'auto', width: '100%'}}>
              <Stack spacing={0}>
                <span style={{color: '#5555FF', fontSize: '25px', fontWeight: 'bold', textAlign: 'center'}}>Betriebskosten nicht verfügbar</span>
              </Stack>
              <Group>
                <span style={{color: '#615C7E', fontSize: '12px', textAlign: 'center'}}>Die Funktion Betriebskosten ist auf mobilen Endgeräten nicht verfügbar.</span>
              </Group>
            </Stack>}
            {isMobile && 
            <Overlay opacity={0} blur={2} zIndex={5} color={'#F7F7FA'}/>}
        </Box>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};
const actions = {
  getRentalUnitTenants,
  setUtilityBillingData
};
export default withRouter(connect(mapStateToProps, actions)(List));
