import React, { Component } from 'react';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { hideNotification, showNotification } from '@mantine/notifications';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';
import PlusIcon from '../../../../assets/images/module/general/PlusIcon.svg';
import RedTick from '../../../../assets/images/module/general/RedTick.svg';
import RedCross from '../../../../assets/images/module/general/RedCross.svg';
import BlueUnitsIcon from '../../../../assets/images/module/general/TenantIconBlue.svg';
import ResetIcon from '../../../../assets/images/module/general/ResetIcon.svg';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { ColorButton, DeleteModal, EmptyStateContainer, MeterModal, RecordOptions, SwitchContainer, ValueText } from '../../../../neoverv';
import { Group, Loader, Popover, Skeleton, Stack, Accordion } from '@mantine/core';
import MeterDataHandler from '../../../../neoverv/helpers/DataHandler/Meter';
import TenantAccessModal from '../../../../neoverv/components/Module/General/CustomModal/TenantAccessModal';
import EmailExistErrorModal from '../../../../neoverv/components/Module/General/CustomModal/EmailExistErrorModal';
import DeactivateTenantModal from '../../../../neoverv/components/Module/General/CustomModal/DeactivateTenantModal';
import TenantResetConfirmationModal from '../../../../neoverv/components/Module/General/CustomModal/TenantResetConfirmationModal';
import {
  setCurrentTenant
} from '../../../../redux/action/Tenant/TenantActions';
import GlobalServices from '../../../../neoverv/helpers/services/GlobalServices';
import dayjs from 'dayjs';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TruncatedTextHandler from '../../../../components/TruncatedTextHandler';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const pStyle= {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: 'rgb(14, 14, 29)'
}
const inputFieldStyle = {
  outline: 'none',
  lineHeight: '1.5em',
  paddingLeft: '0.8rem',
  paddingRight: '0.8rem',
  border: '1px solid #d5dce6',
  borderRadius: '4px',
  color: '#0E0E1D',
  height: '36px',
  background: '#fff',
  width: '150px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}
const tableCellStyle = {
  whiteSpace: 'nowrap', 
  overflow: 'hidden', 
  textOverflow: 'ellipsis', 
  fontSize: '14px', 
  fontFamily: 'Inter, sans-serif', 
  height: 'auto !important',
  fontWeight: '400',
  borderTop: '8px solid white'
}
const chipStyle = {
  backgroundColor: 'white', //'#f2f2fc',
  borderRadius: '4px',
  padding: '2px 6px',
  fontSize: '14px',
  width: 'auto'
}
export class TenantAdministrationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
        tableColumnNames: [
          {
          name:'Mieter',
          key: 'name'
        },{
          name: 'Einheit',
          key: 'rentalUnit'
        },{
          name: 'Einzug',
          key: 'moveIn'
        },{
          name: 'Auszug',
          key: 'moveOut'
        },{
          name: 'Mieterportal Zugang',
          key: 'tenantPortalAccess'
        },{
          name: 'Zugangslink',
          key: 'accessDetails'
        },{
          name: 'Zugangs-Email',
          key: 'accountEmail'
        }],
        data: [],
        page: 0,
        rowsPerPage: 5,
        openMeterModal: false,
        edit: false,
        meter: {name: ''},
        openDelete: false,
        deleteReadingId: null,
        skeletonLoading: false,
        openFound: null,
        openNotFound: null,
        fetch: 10,
        offset: 0,
        openTenantAccessModal: false,
        selectedTenant: null,
        allUsersInAccount: [],
        tenantsLength: 0,
        openEmailExistModal: false,
        selectedTenantForEmailExist: null,
        openDeactiveTenantModal: false,
        openResetTenantConfirmationModal: false,
        selectedResetTenant: null,
        openRecordOption: null,
        hoverRecordOption: null,
        rentalUnits: [],
        loader: false,
        minimize: false,
        accordionState: {0: true}
    };
  }

  componentDidMount = () => {
    this.componentDidMountStuff();
  };

  componentDidMountStuff(){
    this.setState({loader: true}, ()=> GlobalServices._getRentalUnitsByProperty(this, '_handleRentalUnitResponse', this.props.propertySelected));
  }

  _handleRentalUnitResponse(response){
    if(response.status && response.statuscode === 200){
      this.setState({
        rentalUnits: response.result.map((rentalUnit)=> ({...rentalUnit, tenants: []}))
      }, ()=> 
        response.result.map((rentalUnit, indexRentalUnit)=> GlobalServices._getTenantsByRentalUnitID(this, '_handleTenantsResponse', {rentalUnit, eolForRentalUnits: response.result.length === (indexRentalUnit+1)}))
      );
    }else{
      this.setState({loader: false});
    }
  }

  _handleTenantsResponse(response){
    let {rentalUnits} = this.state;
    if(response.statuscode === 200 && response.status){
      rentalUnits= rentalUnits.map((rentalUnit)=> {
        if(rentalUnit.id === response.other.rentalUnit.id){
          rentalUnit.tenants= response.result;
        }
        return rentalUnit;
      });

      this.setState({rentalUnits}, ()=> {
        if(response.other.eolForRentalUnits){
          let tenantsLength = 0;
          rentalUnits.map((unit)=> {
            if(unit.tenants.length > 0 && unit.tenants.find((ten)=> ten.vacant === false) !== undefined){
              unit.tenants.map((ten)=> {
                if(ten.vacant === false){
                  tenantsLength += 1;
                }
              })
            }
          })
          this.setState({tenantsLength});
        }
      });
    }
    
    if(response.other.eolForRentalUnits){
      this.getAllUsersByAccountId();
    }
  }

  // componentDidUpdate(prevProps){
  //   if((prevProps.rentalUnits.length > 0 !== this.props.rentalUnits.length > 0)){
  //     this.componentDidMountStuff();
  //   }
  // }

  getAllUsersByAccountId=()=>{
      this.setState({loader: true});
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.user_api_baseurl + 'GetAllByAccountId?accountId='+ this.props.profileData.accountId+ '&offset=0&fetch=500',
        'GET',
        null,
        null,
        false,
        true,
        true,
        this,
        '_handleGetAllUsersByAccountId',
        null,
        false,
        '',
        { 'Content-Type': 'application/json', Authorization: Autoload.Utils.getAuthToken() },
        true
      );
  }

  _handleGetAllUsersByAccountId(response){
    console.log('_handleGetAllUsersByAccountId: ', response);
    if(response.status && response.statuscode === 200){
      this.setState({allUsersInAccount: response.result, loader: false});
      // intercom update
      let ActiveTenantPortalUsers = response.result.filter((user)=> user.isActivated === true)?.length;
      window.Intercom('update', {
        api_base: "https://api-iam.intercom.io",
        app_id: "vvrs5bx3",
        'No tenant portal users': ActiveTenantPortalUsers
      });
    }
    // else if(response.statuscode === 429){
    //   setTimeout(() => {
    //     this.getAllUsersByAccountId();  
    //   }, 3000);
    // }

    if(response.statuscode !== 429){
      this.setState({loader: false});  
    }
  }

  putResetTenant=(tenantData)=>{
    const payload = {
      accountId: this.props.accountData.accountId,
      tenantId: tenantData.id,
      email: tenantData.email,
      firstName: tenantData.fullName
    }
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_api_baseurl + tenantData.id +'/TenantReset',
      'PUT',
      payload,
      null,
      false,
      true,
      true,
      this,
      '_handlePutResetTenant',
      null,
      false,
      '',
      { 
        'Content-Type': 'application/json', 
        Authorization: Autoload.Utils.getAuthToken() 
      }
    );
}

_handlePutResetTenant(response){
  if(response.status && response.statuscode === 200){
    showNotification({
      message: 'Mieterzugang zurückgesetzt',
      icon: <img src={GreenTick} />
    })
    this.getAllUsersByAccountId();
  }
  this.setState({selectedResetTenant: null});
}

  activateUser=(ten)=>{
    console.log('activateUser: ', ten);
    if(this.state.allUsersInAccount.find((user)=> user.userId === ten.id) === undefined) {
      let payload = {
        accountId: this.props.profileData.accountId,
        firstName: ten.fullName,
        email: ten.email,
        phoneNumber: ten.phone,
        tenantId: ten.id
      }
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.user_api_baseurl + 'tenant',
        'POST',
        payload,
        null,
        false,
        true,
        true,
        this,
        '_handleActivateUser',
        {tenantId: ten.id},
        false,
        '',
        { 'Content-Type': 'application/json', Authorization: Autoload.Utils.getAuthToken() },
        true
      );
    } else {
      let user = this.state.allUsersInAccount.find((user)=> user.userId === ten.id);
      if(user.isActive){
        this.setState({openDeactiveTenantModal: true});
      }else{
        Autoload.HttpRequestAxios.HttpRequest(
          Autoload.Setting.user_api_baseurl + user.userId +'/TenantActivation?isActivate='+ !user.isActive,
          'PUT',
          null,
          null,
          false,
          true,
          true,
          this,
          '_handleActivateUser',
          {tenantId: ten.id},
          false,
          '',
          { 'Content-Type': 'application/json', Authorization: Autoload.Utils.getAuthToken() },
          true
        );
      }
    }
  }

  // deleteUser=(id)=>{
  //   Autoload.HttpRequestAxios.HttpRequest(
  //     Autoload.Setting.user_api_baseurl +id+'/tenant',
  //     'DELETE',
  //     null,
  //     null,
  //     false,
  //     true,
  //     true,
  //     this,
  //     '_handleDeleteUser',
  //     {tenantId: id},
  //     false,
  //     '',
  //     { 'Content-Type': 'application/json', Authorization: Autoload.Utils.getAuthToken() },
  //     true
  //   );
  // }
  _handleActivateUser(response){
    console.log('_handleActivateUser: ', response);
    if(response.status && response.statuscode === 200){
      this.getAllUsersByAccountId();
    }else if(response.statuscode === 400 && response.message === 'EMAIL_EXISTS'){
      // showNotification({
      //   id: 'emailExist',
      //   message: "Es ist ein Fehler aufgetreten. Die Email-Adresse ist nicht verfügbar.",
      //   icon: <img src={RedCross} alt='red-tick'/>,
      // })
      this.setState({openEmailExistModal: true, selectedTenantForEmailExist: response.other.tenantId});
    }
  }

  // componentDidUpdate= (prevProps)=>{
  //   if(prevProps.rentalUnits !== this.props.rentalUnits){
  //     let tenantsLength = 0;
  //     this.props.rentalUnits.map((unit)=> {
  //       if(unit.tenantsData.length > 0 && unit.tenantsData.find((ten)=> ten.vacant === false) !== undefined){
  //         unit.tenantsData.map((ten)=> {
  //           if(ten.vacant === false){
  //             tenantsLength += 1;
  //           }
  //         })
  //       }
  //     })
  //     this.setState({tenantsLength});
  //   }
  // }

  componentWillUnmount() {
    
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }



  getTenantsByRentalUnit=()=>{
    
  }

  ////sorting functions start
  descendingComparator = (a, b, orderBy)=> {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  getComparator= (order, orderBy)=> {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }
  
  stableSort=(array, comparator)=> {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  onRequestSort = (event, property) => {
    console.log('onRequestSort: ', property);
    const {order, orderBy} = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState({order: isAsc ? 'desc' : 'asc'});
    this.setState({orderBy: property});
  };
////sorting functions end

///// paging functions start
handleChangePage = (event, newPage) => {
  console.log('handleChangePage: ', newPage);
  this.setState({page: newPage});
};
handleChangeRowsPerPage = (event) => {
  this.setState({rowsPerPage: parseInt(event.target.value, 10)});
  this.setState({page: 0});
};
///// paging functions end
deleteReadings= (readingId)=>{
  hideNotification('deleteLogs');
  Autoload.HttpRequestAxios.HttpRequest(
    Autoload.Setting.meter_api_base_url+ 'readings/' + readingId,
    'DELETE',
    null,
    null,
    false,
    true,
    false,
    this,
    '_handleDeleteReadings',
    null,
    false,
    '',
    {
      'Content-Type': 'application/json',
      Authorization: Autoload.Utils.getAuthToken(),
    }
  );
}

_handleDeleteReadings=(response)=>{
  if(response.status){
    showNotification({
      message: 'Die Ablesedaten wurden gelöscht.',
      icon: <img src={RedTick} alt='red-tick'/>,
      id: 'deleteLogs'
    })
    this.state.offset === 1 && this.setState({offset: 0});
    this.toggleDeleteModal();
    this.getUploadsLog(true);
  }
}

toggleDeleteModal= ()=>{
  this.setState({openDelete: !this.state.openDelete});
}

_addNewMeter=(data)=>{
  {
    data.propertyId = this.props.propertySelected && this.props.propertySelected.id;
    const payload = MeterDataHandler.generateMeterDatawithAllocationKey(data);
    console.log(payload, data, 'data in meter=========');
    let btnelement = document.getElementById('meter-button-container');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url + 'meters',
      'POST',
      payload,
      btnelement.children[0],
      false,
      true,
      true,
      this,
      '_handleCreateMeter',
      { btnelement: btnelement },
      false,
      '',
      { 'Content-Type': 'application/json', Authorization: Autoload.Utils.getAuthToken() }
    );
  }
}

_handleCreateMeter=(response)=>{
  if (response.statuscode !== 200) {
    if(response.statuscode === 409) {
      showNotification({
        id: 'meterDuplicate',
        message: "Diese Zählernummer wird bereits verwendet. Bitte wählen Sie eine andere Zählernummer.",
        icon: <img src={RedCross} alt='red-tick'/>,
      });
    }else {
      showNotification({
        message: "Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.",
        icon: <img src={RedCross} alt='red-tick'/>,
      });
    }
  } else{
    window.Intercom("update", {
      api_base: "https://api-iam.intercom.io",
      app_id: "vvrs5bx3",
      'Meter Created': true
    });
    showNotification({
      message: "Der Zähler wurde angelegt.",
      icon: <img src={GreenTick} alt='green-tick'/>
    });
    window.Intercom('trackEvent', 'meter-created');
  }

  this.setState({ openMeterModal: !this.state.openMeterModal })
}
  renderChip=(item, type='meterNumbersNotFound', collapse=false)=>{
    return (
    <Group>
      <div style={chipStyle}>
        <span style={{display: 'flex', alignItems: 'center'}}>
          <span className='chipSpanText' style={{whiteSpace: 'nowrap', overflowX: item.length >= 30 && 'scroll', textOverflow: item.length >= 30 && 'ellipsis', width: item.length >= 30 ? '130px' : 'auto'}}>
            {item}
          </span>
        {type === 'meterNumbersNotFound' && <img src={PlusIcon} 
        onClick={()=> 
        this.setState({meter: {number: item}, openMeterModal: true})
        } style={{marginLeft: '8px', cursor: 'pointer', marginRight: collapse === false && item.length >= 14 && '8px'}} width={'10px'} height={'10px'} />}
        </span>
      </div>
    </Group>);
  }
  handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) { 
      this.setState({fetch: this.state.fetch + 10}, ()=> this.getUploadsLog())
    }
  }

  setCurrentTenant=(ten)=>{
    this.props.setCurrentTenant(ten, {
      createdOn: "",
      current: ten.isLastRealTenant,
      email: ten.email,
      id: ten.id,
      in: ten.moveInDate,
      name: ten.fullName,
      note: ten.notes,
      out: ten.moveOutDate,
      phone: ten.phone,
      rentalUnitId: ten.rentalUnitId,
      type: ten.contractType,
      updatedOn: "",
      vacant: ten.vacant,
      numberOfOccupants: ten.numberOfOccupants
    });
  }

  render() {
    const {isMobile, isTablet, currentSubscription, profileData} = this.props;
    const {tableColumnNames, openMeterModal, edit, meter, skeletonLoading, openTenantAccessModal, selectedTenant, allUsersInAccount, tenantsLength, openEmailExistModal, selectedTenantForEmailExist, openDeactiveTenantModal, openResetTenantConfirmationModal, selectedResetTenant, openRecordOption, hoverRecordOption, rentalUnits, loader, minimize, accordionState} = this.state;
    const createSortHandler = (property) => (event) => {
      this.onRequestSort(event, property);
    };

    console.log('rentalUnits: ', rentalUnits);
    console.log('profileData: ', profileData, allUsersInAccount);
    return (
        <div 
        style={{
          width: '100%', 
          background: 'white',
          boxShadow: '0 1px 3px rgba(0,0,0,.08)', 
          borderRadius: '4px'
          }}
          //onScroll={this.handleScroll}
          >
            <Accordion
            classNames={{
                label: 'tenant-administration-accordion-label',
                contentInner: 'tenant-administration-accordion-inner-content',
                control: 'tenant-administration-accordion-control',
                icon: 'tenant-administration-accordion-icon',
            }}
            // styles={{
            //     content: {
            //         display: 'none',
            //         boxShadow: 'none',
            //     }
            // }}
            iconPosition='right'
            initialItem={0}
            state={accordionState}
            onChange={(state)=> this.setState({accordionState: state})}
            >
              <Accordion.Item 
              label={
              <Group
              position='apart'
              style={{
                  padding: '12.1px 24px',
                  borderBottom: minimize === false ? '1px solid #dadae6' : 'none',
                  borderTopLeftRadius: '4px',
                  borderTopRightRadius: '4px',
                  borderBottomLeftRadius: minimize && '4px',
                  borderBottomRightRadius: minimize && '4px',
                  background: 'white',
                  cursor: 'pointer'
              }}
              onClick={()=> this.setState({minimize: !minimize})} noWrap>
                  <Group className='tenant-administration-display-consumptions'>
                    <h5 
                    style={{
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: '#0e0e1d',
                      marginBottom: '0px'
                    }}
                    className={'tenant-administration-manage-access tooltips'}>
                      Zugänge verwalten
                    </h5>
                  </Group>
                  <Group noWrap>
                    {!minimize ? <ExpandMoreIcon style={{color: '#13135C'}} /> : <NavigateNextIcon style={{color: '#13135C'}} />}
                  </Group>
              </Group>}>
                {/* <Skeleton visible={skeletonLoading}> */}
                  <div style={{
                    padding: '12.1px 24px',
                    }}>

                      {loader ?
                      <Group style={{width: '100%', height: '330px'}} position='center' align={'center'}>
                        <Loader/>
                      </Group>
                      
                      :
                      currentSubscription?.subscriptionKey.split('_')[0] === 'PROFESSIONAL' && rentalUnits.length !== 0 && tenantsLength > 0 ?
                      <>
                        <Group noWrap position='right' style={{width: '100%'}}>
                          <div>
                            <ColorButton
                            text={'Einladungen erstellen'}
                            className={'purple-btn'}
                            buttonStyle={{opacity: allUsersInAccount.find((user)=> user.userId !== profileData.id && user.isActive) === undefined ? 0.6 : 1}}
                            onClick={(event)=> {
                              event.stopPropagation();
                              if(allUsersInAccount.find((user)=> user.userId !== profileData.id && user.isActive) === undefined){
                                showNotification({
                                  message: 'Bitte aktivieren Sie Mieterzugänge, um Einladen zu versenden.',
                                  icon: <img src={RedTick} alt='red-tick'/>,
                                  id: 'sendAllMessagedisableError'
                                });
                              }else{
                                this.props.history.push('/messaging/newMessage', {messageType: 'tenantPortalMessage', multipleTenants: allUsersInAccount.filter((user)=> user.isActive && user.isActivated === false)});
                              }
                            }}
                            />
                          </div>
                        </Group>
                        <TableContainer style={{ marginTop: '0px'}}>
                        <Table stickyHeader
                        className='fixed'
                        style={{
                          border: 'none'
                        }}
                        >
                        <TableBody>
                            <TableRow>
                                {tableColumnNames.map((row, index) => {
                                return <TableCell 
                                        style={{
                                            whiteSpace: 'nowrap',
                                            border: 'none',
                                            paddingBottom: '0px',
                                            fontSize: '12px',
                                            fontWeight: '400',
                                            color:'#727293',
                                        }} 
                                        key={index}
                                        >
                                        <span className={row.name === 'Zugangs-Email' && 'tooltips tenant-portal-table-header-access-email'}>{row.name}</span>
                                        </TableCell>;
                                })}
                            </TableRow>
                            {rentalUnits
                            .sort((a, b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                            .map((row, index) => {
                                return (
                                  row.tenants.map((ten, i)=>{
                                      if(ten.vacant === false){
                                        console.log('ten'+index+'-'+i, ten);
                                          return (
                                          <TableRow className="table-data" style={{background: '#F7F7FA', height: '70px'}} key={`rentalUnit-${index}-tenant-${i}`}>
                                              <TableCell style={{...tableCellStyle, ...{cursor: 'pointer'}}} 
                                              onClick={()=> {
                                                this.setCurrentTenant(ten);
                                                this.props.history.push(`/user/tenant/information?tenantId=${ten.id}&tenantAdministration=false`);
                                              }}>
                                                <TruncatedTextHandler text={ten.fullName} maxWidth={120} marginTop={'5px'}/>
                                              </TableCell>
                                              <TableCell style={{...tableCellStyle}}>
                                                <TruncatedTextHandler text={row.name} maxWidth={120} marginTop={'5px'}/>
                                              </TableCell>
                                              <TableCell style={{...tableCellStyle}}>
                                                  {ten.moveInDate ? dayjs(ten.moveInDate).format('DD.MM.YYYY') : '-'}
                                              </TableCell>
                                              <TableCell style={{...tableCellStyle}}>
                                                  {ten.moveOutDate ? dayjs(ten.moveOutDate).format('DD.MM.YYYY') : '-'}
                                              </TableCell>
                                              <TableCell style={{...tableCellStyle}}>
                                                  <SwitchContainer
                                                  disabled={false}
                                                  switchText={'aktivieren'}
                                                  onColor="#5555FF"
                                                  offColor="#DADAE6"
                                                  onChange={()=> ten.email ? this.setState({selectedTenant: ten}, ()=> this.activateUser(ten)) : this.setState({openTenantAccessModal: true, selectedTenant: ten})}
                                                  checked={allUsersInAccount.find((user)=> user.userId === ten.id && user.isActive) !== undefined}
                                                  height={16}
                                                  width={28}
                                                  handleDiameter={1.8}
                                                  />
                                              </TableCell>
                                              <TableCell style={{...tableCellStyle}}>
                                                {allUsersInAccount.find((user)=> user.userId === ten.id) !== undefined ?
                                                  allUsersInAccount.find((user)=> user.userId === ten.id).isActivated ?
                                                  'aktiviert'
                                                  :
                                                  allUsersInAccount.find((user)=> user.userId === ten.id).isActive ?
                                                  <span style={{color: 'rgb(85, 85, 255)', cursor: 'pointer'}} 
                                                  onClick={()=>
                                                      {
                                                        let user = allUsersInAccount.find((user)=> user.userId === ten.id);
                                                        this.setState({openTenantAccessModal: true, selectedTenant: {...ten, passwordResetCode: user !== undefined ? user.passwordResetCode : null, isActive: user.isActive, isActivated: user.isActivated, email: allUsersInAccount.find((user)=> user.userId === ten.id)?.email}})
                                                      }
                                                  }>anzeigen</span>
                                                  :
                                                  '-'
                                                :
                                                '-'}
                                              </TableCell>
                                              <TableCell style={{...tableCellStyle}}>
                                                <TruncatedTextHandler text={allUsersInAccount.find((user)=> user.userId === ten.id)?.email} maxWidth={150} marginTop={'5px'}/>
                                              </TableCell>
                                              <TableCell style={{...tableCellStyle}}>
                                                {allUsersInAccount.find((user)=> user.userId === ten.id && user.isActivated) !== undefined &&
                                                <ColorButton
                                                text={'Vorschau'}
                                                className={'gray-btn'}
                                                onClick={()=> window.open('https://dev.hellohousing-mieterportal.de','hellohousing-mieterportal','directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=1000,height=700')}
                                                />}
                                              </TableCell>
                                              <TableCell width={'10%'} style={{...tableCellStyle, ...{textAlign: 'right'}}}>
                                                {allUsersInAccount.find((user)=> user.userId === ten.id)?.isActive &&
                                                <>
                                                <Popover
                                                opened={openRecordOption === ten.id}
                                                onClose={() => this.setState({openRecordOption: null})}
                                                target={
                                                  <Group onClick={()=> this.setState({openRecordOption: openRecordOption === ten.id ? null : ten.id})}>
                                                    <RecordOptions
                                                    customButtonAction={true}//()=> this.setState({openRecordOption: openRecordOption === ten.id ? null : ten.id})}
                                                    buttonStyle={{background: 'none', border: 'none'}}
                                                    //showResetButton={true}
                                                    //onReset={()=> this.setState({openResetTenantConfirmationModal: true, selectedResetTenant: ten})}
                                                    />
                                                  </Group>}
                                                width={'160px'}
                                                position="bottom"
                                                placement='start'
                                                styles={{
                                                  body: {
                                                    boxShadow: 'none',
                                                    border: '1px solid rgba(0,0,0,.1)',
                                                    borderRadius: '3px',
                                                    padding: '10px 0px'
                                                  }
                                                }}
                                                spacing={0}
                                                gutter={0}
                                                >
                                                  <Stack>
                                                    <Group onClick={()=> this.setState({openResetTenantConfirmationModal: true, selectedResetTenant: ten, openRecordOption: null})} noWrap style={{cursor: 'pointer', background: hoverRecordOption === `${ten.id}_1` && '#f2f2fc', height: '23.1px', padding: '.25rem 1.5rem'}} spacing={5} onMouseEnter={()=> this.setState({hoverRecordOption: `${ten.id}_1`})} onMouseLeave={()=> this.setState({hoverRecordOption: null})}>
                                                      <img width={'18px'} style={{marginTop: '-3px'}} src={ResetIcon}/>
                                                      <span style={{fontSize: '14px', fontWeight: '500', fontFamily: 'sans-serif'}}>Zurücksetzen</span>
                                                    </Group>
                                                  </Stack>
                                                </Popover>
                                              </>}
                                              </TableCell>
                                          </TableRow>)
                                      }
                                  })
                                );
                            })}
                            </TableBody>
                        </Table>
                        </TableContainer>
                      </>
                        :
                        <Group position='center' style={{background: 'rgb(247, 247, 250)'}}>
                          <EmptyStateContainer
                            heading="Es sind noch keine Mietverhältnisse vorhanden."
                            subheading={"Sie müssen zunächst Mietverhältnisse anlegen, um deren Zugang zum Mieterportal einzurichten."}
                            icon={BlueUnitsIcon}
                            showBtn={true}
                            styleMainContainer={{margin: 50}}
                            btnText='Mieter anlegen'
                            btnHandler={()=> this.props.history.push('/user')}
                          />
                        </Group>
                      }
                  
                  </div>
                  <MeterModal
                    history={this.props.history}
                    meter={meter}
                    className="add-meter-modal"
                    open={openMeterModal}
                    edit={edit}
                    meterUploads={true}
                    addNewMeter={(newMeterData) => this._addNewMeter(newMeterData)}
                    toggle={() => this.setState({ openMeterModal: !this.state.openMeterModal, edit: false })}
                    propertyId={this.props.propertySelected.id}
                    //rentalUnitName={this.state.unitName}
                    //rentalUnitIndex={this.state.unitIndex}
                  />

                  {openTenantAccessModal &&
                  <TenantAccessModal
                  open={openTenantAccessModal}
                  selectedTenant={selectedTenant}
                  history={this.props.history}
                  setCurrentTenant={()=> this.setCurrentTenant(selectedTenant)}
                  toggle={()=> this.setState({openTenantAccessModal: false})}
                  />}
                  {openEmailExistModal && 
                  <EmailExistErrorModal
                  open={openEmailExistModal}
                  toggle={()=> this.setState({openEmailExistModal: false})}
                  selectedTenant={selectedTenantForEmailExist}
                  history={this.props.history}
                  />}
                  {openDeactiveTenantModal &&
                  <DeactivateTenantModal
                  open={openDeactiveTenantModal}
                  toggle={()=> this.setState({openDeactiveTenantModal: false})}
                  selectedTenant={selectedTenant}
                  history={this.props.history}
                  getAllUsersByAccountId={()=> this.getAllUsersByAccountId()}
                  />}
                  <DeleteModal
                  parent={this.props.parent}
                  open={this.state.openDelete}
                  toggle={() => this.toggleDeleteModal()}
                  handleDelete={() => this.deleteReadings(this.state.deleteReadingId)}
                  modalId="ct-delete-modal-footer"
                  showtext={false}
                  typeText={'Sind Sie sicher, dass Sie diese Ablesung löschen wollen? Alle Ablesedaten gehen unwideruflich verloren.'}
                  showDeleteText={false}
                  textShowModal={'Ablesung löschen'}
                  />
                  {openResetTenantConfirmationModal&&
                  <TenantResetConfirmationModal
                  open={openResetTenantConfirmationModal}
                  toggle={()=> this.setState({openResetTenantConfirmationModal: false, selectedResetTenant: null})}
                  resetTenant={()=> this.setState({openResetTenantConfirmationModal: false},()=> this.putResetTenant(selectedResetTenant))}
                  />}
                {/* </Skeleton> */}
              </Accordion.Item>
            </Accordion>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    rentalUnits: state.tenantDataReducer.rentalUnits,
    profileData: state.generalReducer.profile,
    accountData: state.generalReducer.accountData,
    currentSubscription: state.generalReducer.currentSubscription,
  };
};
const actions={
  setCurrentTenant
}
export default connect(mapStateToProps, actions)(TenantAdministrationList);
