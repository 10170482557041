import React, { Component, forwardRef } from 'react';
import { Route } from 'react-router-dom';
import { ButtonGroup, Button } from 'react-bootstrap';
import { ColorButton, FiltersContainer, HeadingContainer, RecordOptions, SearchContainer } from '../../../../neoverv';
import Autoload from '../../../../neoverv/Autoload';
import { withRouter } from 'react-router-dom';
import MessagingEditor from './MessagingEditor';
import { IconButton, TableContainer, Tooltip } from '@material-ui/core';
import { Box, CloseButton, Group, Input, Loader, MultiSelect, Popover, Select, Stack } from '@mantine/core';
import Up from '../../../../assets/images/module/general/SelectArrowUp.svg';
import Down from '../../../../assets/images/module/general/SelectArrowDown.svg';
import MessagingEditorMantine from './MessagingEditorMantine';
import Avatar from '../../../../assets/images/module/general/userAvatar.svg';
import defaultAvatar from '../../../../assets/images/module/general/defaultAvatarPicture.svg';
import SearchIcon from '../../../../assets/images/module/general/SearchIcon.svg';
import AddTenantBlue from '../../../../assets/images/module/general/AddTenantBlue.svg';
import AddAllTenantsBlue from '../../../../assets/images/module/general/AddAllTenantsBlue.svg';
import RemoveAllTenantsBlue from '../../../../assets/images/module/general/RemoveAllTenantsBlue.svg';
import breadcrumbsContent from '../../../../assets/breadcrumbs.json';
import HelpLogo from '../../../../assets/images/module/general/book.svg';
import TourIcon from '../../../../assets/images/module/general/TourIcon.svg';
import RedCross from '../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';
import RightArrowPartner from '../../../../assets/images/module/general/RightArrowPartner.svg';
import PrintIconBlue from '../../../../assets/images/module/general/PrintIconBlue.svg';
import SelfIcon from '../../../../assets/images/module/general/SelfIcon.svg';
import TenantSingleIcon from '../../../../assets/images/module/general/TenantSingleIcon.svg';
import TenantSingleIconBlue from '../../../../assets/images/module/general/TenantSingleIconBlue.svg';
import TenantLiteIcon from '../../../../assets/images/module/general/TenantLite.svg';
import buildingIcon from '../../../../assets/images/module/general/building_icon.svg';
import GoldenLockIcon from '../../../../assets/images/module/general/GoldenLockIcon.svg';
import BlueTick from '../../../../assets/images/module/general/BlueTick.svg'
import PdfIcon from '../../../../assets/images/module/general/PdfNewIcon.svg';
import { setUtilityBillingData, setCurrentBillingId } from '../../../../redux/action/UtilityBilling/UtilityBillingActions';
import { setPublishMessageId, setRefreshMessagesData, setAccountData, setCurrentMessagesData } from '../../../../redux/action/General/generalActions';

import AddIcon from '@material-ui/icons/Add';
import { connect } from 'react-redux';
import AddMessagingRecipientsModal from '../../../../neoverv/components/Module/General/CustomModal/AddMessagingRecipientsModal';
import { hideNotification, showNotification } from '@mantine/notifications';
import MessagingPricingModal from '../../../../neoverv/components/Module/General/CustomModal/MessagingPricingModal';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import MessagePreviewModal from '../../../../neoverv/components/Module/General/CustomModal/MessagePreviewModal';
import MessaginTemplateContent from '../../../../assets/messaging-templates.json'
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { Modifier } from 'draft-js';
import MessageConfirmationModal from '../../../../neoverv/components/Module/General/CustomModal/MessageConfirmationModal';
import MessageDeleteModal from '../../../../neoverv/components/Module/General/CustomModal/MessageDeleteModal';
import dayjs from 'dayjs';
import BulkDownloadForMessageModal from '../../../../neoverv/components/Module/General/CustomModal/BulkDownloadForMessageModal';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '../../../../neoverv/components/SVGs/downloadIcon';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SendIcon from '@material-ui/icons/Send';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { loader, checkProperty } = Autoload.MainHelper;

const MOBILE_SIZE = 992;

  function Value({
    value,
    label,
    onRemove,
    classNames,
    ...others
  }) {
    console.log('Value: ', value, label, others);
    return (
        <Group spacing={5} className='message-card-ten-cards' key={value} noWrap style={{height: '20.6px', maxHeight: '20.6px', background: '#F2F2FC', paddingRight: others.name.length > 4 ? 10 : 3, marginRight: '3px'}}>
            <Group noWrap spacing={5} style={{minWidth: '55px'}}>
                <Group style={{background: '#454576', width: '18px', borderRadius: '120px'}} position='center' align={'center'}>
                  <span style={{textTransform: 'capitalize', color: 'white'}}>{others.name[0]}</span>
                </Group>
                <span style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{others.name}</span>
            </Group>
            {/* <Group>
                <CloseButton
                onMouseDown={onRemove}
                variant="transparent"
                size={15}
                iconSize={14}
                tabIndex={-1}
                />
            </Group> */}
        </Group>
    );
  }
  
  const Item = forwardRef(({ label, value, ...others }, ref) => {
    console.log('Item: ', label, value, others);
    //const Flag = flags[value];
    return (
      <div ref={ref} {...others}>
        {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box mr={10}>
            <img src={others.picture}/>
          </Box>
          <div>{others.name}</div>
        </Box> */}
        <Group spacing={5} className='message-card-ten-cards' key={value} style={{height: '20.6px', maxHeight: '20.6px', background: 'transparent', width: 'auto'}}>
            <img src={others.picture} style={{height: '18.6px', maxHeight: '18.6px'}}/>
            <span>{others.name}</span>
        </Group>
      </div>
    );
  });

class NewMessageInnerContent extends Component {
  state = {
    tenant: [{
        value: 'id1',
        name: 'Ten1',
        picture: defaultAvatar,
        label: 'Ten1'
    }, {
        value: 'id2',
        name: 'Ten2',
        picture: Avatar,
        label: 'Ten2'
    }, {
        value: 'id3',
        name: 'Ten3',
        picture: defaultAvatar,
        label: 'Ten3'
    }, {
        value: 'id4',
        name: 'Ten4',
        picture: Avatar,
        label: 'Ten4'
    }, {
        value: 'id5',
        name: 'Ten5',
        picture: defaultAvatar,
        label: 'Ten5'
    }],
    selectedRecipients: [],
    dropdownIcon: Down,
    tenantsLength: null,
    openRecipientsModal: false,
    openMessagingPricingModal: false,
    paymentDetails: null,
    allRecipients: [],
    allRecipientsOldState: [],
    messageData: {
      id: null,
      accountId: null,
      propertyId: null,
      utilityBillingId: null,
      senderId: null,
      subject: null,
      body: null,
      status: null
    },
    messageDataOldState: {
      id: null,
      accountId: null,
      propertyId: null,
      utilityBillingId: null,
      senderId: null,
      subject: null,
      body: null,
      status: null
    },
    draftOnce: true,
    tenants: [], 
    tenantsData: [],
    messageRecipientsLoader: false,
    postalFee: null,
    openPreviewModal: false,
    messageSavingLoader: false,
    editorState: EditorState.createEmpty(),
    subjectInFocus: false,
    openPostalFeeStatus: false,
    openMessageConfirmationModal: false,
    openMessageDeletionModal: false,
    isMobile: window.innerWidth <= MOBILE_SIZE,
    openBulkMessageDownloadModal: false,
    hoverOnStarter2: false,
    inPublishProcess: false,
    messageSavingStatusPopper: false
  };

  myRef=null;
  subjectEditorRef=null;

  constructor(props){
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount(){
    this.componentDidMountStuff();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps){
    if(prevProps.utilityBillingData.length === 0 && this.props.utilityBillingData.length > 0){
      this.componentDidMountStuff();
    }
  }

  handleResize = () => {
    // TODO: this causes data refetch on the meters reading details screen...
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  componentDidMountStuff=()=>{
    const {rentalUnits, propertySelected, numberBillingStatementTableRows, numberMeterReadingsTableRows, draftMessageDetails=null, utilityBilling, setCurrentBillingId, setUtilityBillingData, forward, replyAll} = this.props;
    console.log('tenantsData: ', this.props.rentalUnits);
    console.log('accountData: ', this.props.accountData);
    console.log('componentDidMount: ', numberBillingStatementTableRows, numberMeterReadingsTableRows);
    console.log('location: ', window.location.href);
    console.log('this.props.showUtilityBillingMessages: ', this.props.showUtilityBillingMessages);
    let {tenants} = this.state;
    this.getPaymentDetails();
    this.getAccounts();

    if(window.location.href.includes('messaging/newMessage')){
      document.getElementById("utility-message-draft-3").addEventListener('click', this.checkMessageData);
    }
    //this.postMessages('Draft');
    // if(this.props.showUtilityBillingMessages && window.location.href.includes('ub-step=billing-statements-sending')){
    //   document.getElementById("utility-message-draft").addEventListener('click', this.handleAutoSave);
    //   document.getElementById("utility-message-draft-2").addEventListener('click', this.handleAutoSave);
    // }else 
    if(this.props.showUtilityBillingMessages && window.location.href.includes('messaging/newMessage')){
      setUtilityBillingData([utilityBilling]);
      // document.getElementById("utility-message-draft-3").addEventListener('click', this.handleAutoSave);
      // console.log('utility-message-draft-3: ', document.getElementById("utility-message-draft-3"));
    }

    if(utilityBilling){
      setCurrentBillingId(utilityBilling.id);
    }
    //
    let ub= utilityBilling ? utilityBilling : this.props.utilityBillingData.find((ub1)=> ub1.id === this.props.currentDataId);
    console.log('ub: ', ub);
    rentalUnits.map((rentalUnit)=>
      rentalUnit.tenantsData.filter((row)=> row.vacant === false).map((tenant)=> {
        // selectedRecipients.push({
        //   id: tenant.id,
        //   isEmailEnabled: checkProperty(tenant, 'email', false) ? true : false,
        //   isPostEnabled: true
        // });
        if(this.props.showUtilityBillingMessages){
          if((dayjs(tenant.in, 'DD.MM.YYYY').isBefore(dayjs(ub?.billingPeriod ? ub?.billingPeriod.endDate : ub?.billingPeriodEnd))) && (tenant.out.length > 0 ? dayjs(tenant.out, 'DD.MM.YYYY').isAfter(dayjs(ub?.billingPeriod ? ub?.billingPeriod.startDate : ub?.billingPeriodStart)) : true)){
            tenants.push({
              ...tenant,
              street: propertySelected.propertyAddress,
              city: propertySelected.propertyCity,
              zipCode: propertySelected.propertyZipCode,
              value: tenant.id,
              label: tenant.name,
              isEmailEnabled: false,
              isPostEnabled: false,
              isHiddenForTenantPortal: false
            });
          }
        }else{
          tenants.push({
            ...tenant,
            street: propertySelected.propertyAddress,
            city: propertySelected.propertyCity,
            zipCode: propertySelected.propertyZipCode,
            value: tenant.id,
            label: tenant.name,
            isEmailEnabled: false,
            isPostEnabled: false,
            isHiddenForTenantPortal: false
          });
        }
        
      })
    );
    console.log('tenants_!1: ', tenants);
    this.setState({tenants}, ()=> 
    this.props.showUtilityBillingMessages && window.location.href.includes('ub-step=billing-statements-sending') ? this.getUBMessagesByUtilityBillingId() : 
      (draftMessageDetails === null || forward || replyAll) ? 
      this.postMessages('Draft', true) : 
      this.setState({messageData: draftMessageDetails, messageDataOldState: draftMessageDetails}, ()=> {
        draftMessageDetails.subject && this.htmlToDraftConversion(draftMessageDetails.subject);
        this.getMessageRecipientsByMessageId(draftMessageDetails.id);
        if(draftMessageDetails.utilityBillingId){
          this.getBillingStatements();
        }else{
          this.getMessagePostalFee();
        }
      }));
  }
  setSubjectEditorRef=(ref)=>{
    console.log('subjectEditorRef: ', ref);
    this.subjectEditorRef= ref;
  };

  // componentDidUpdate(prevProps, prevState){

    
  // }


  checkMessageData=()=> {
    const {messageData, allRecipients} = this.state;
    const {messageType} = this.props;
    console.log('checkMessageData: ', messageData);
    if((messageData.subject === null || messageData.subject === '<p></p>\n' || messageData.subject === '') && (messageData.body === null || messageData.body === '<p></p>\n' || messageData.body === '') && allRecipients.length === 0){
      this.deleteMessagesById(messageData.id);
    }
  }

  handleAutoSave=()=> {
    let stateChanged = JSON.stringify(this.state.messageDataOldState) !== JSON.stringify(this.state.messageData);
    let {messageData, messageDataOldState} = this.state;
    console.log('stateChanged: ', stateChanged, messageData.subject, messageDataOldState.subject);
    if(stateChanged){
      if(messageData.id){
        //messageData.subject !== '<p></p>\n' && 
        this.putMessagesById();
      }else// if((messageData.subject !== null && messageData.subject !== '<p></p>\n') || (messageData.body !== null && messageData.body !== '<p></p>\n'))
      {
        this.postMessages('Draft');
      };
    }
  }

  componentWillUnmount(){
    // if(this.props.showUtilityBillingMessages && window.location.href.includes('ub-step=billing-statements-sending')){
    //   document.getElementById("utility-message-draft").removeEventListener('click', this.handleAutoSave);
    //   document.getElementById("utility-message-draft-2").removeEventListener('click', this.handleAutoSave);
    // }
    //document.getElementById("utility-message-draft-3").removeEventListener('click', this.handleAutoSave);
    const {messageType} = this.props;
    const {messageData, inPublishProcess} = this.state;
    if(messageData.templateType === 'RentalPayment' && inPublishProcess === false &&
    messageData.body === MessaginTemplateContent.find((template)=> template['template-identifier'] === 'tenant-rent-message-template').body2.replace(/\\/, '') &&
    messageData.subject === MessaginTemplateContent.find((template)=> template['template-identifier'] === 'tenant-rent-message-template').subject2.replace(/\\/, '')){
      this.deleteMessagesById(messageData.id, true);
    }

    if(messageType === 'tenantPortalMessage' && inPublishProcess === false){
      this.deleteMessagesById(messageData.id, true);
    }

    this.props.setCurrentMessagesData(null);

    window.removeEventListener('resize', this.handleResize);
    hideNotification('accountInformationIncompleteError');
    hideNotification('autoSaveMessageData');
    hideNotification('recipientsWithError');
    hideNotification('propertyAddressInformationIncomplete');
  }

  getPaymentDetails = () => {
    const userAccountID = this.props.profile.accountId;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_api_baseurl + userAccountID + '/paymentMandate',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_account_payment_details',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handle_account_payment_details(response){
    if(response.status && response.statuscode === 200){
      this.setState({paymentDetails: response.result});
    }
  }
  // messages end-points start
  postMessages = (status, firstDraft=false) => {
    this.setState({messageSavingLoader: true, messageSavingStatusPopper: true});
    const {accountData, propertySelected, currentDataId, showUtilityBillingMessages, utilityBillingData, utilityBilling, draftMessageDetails, forward, replyAll, messageType, rentalPaymentId, accessLink} = this.props;
    const messageData = (forward || replyAll) ? draftMessageDetails : this.state.messageData;
    let currentBillingPeriod= showUtilityBillingMessages ? utilityBillingData.find((ub)=> ub.id === currentDataId)?.billingPeriod : null;
    console.log('currentBillingPeriod: ', currentBillingPeriod, currentDataId);
    let payload= {
      accountId: accountData?.accountId,
      propertyId: propertySelected.id,
      utilityBillingId: showUtilityBillingMessages ? currentDataId : null,
      subject: (messageType === 'tenantRentMessage' && firstDraft) ? MessaginTemplateContent.find((template)=> template['template-identifier'] === 'tenant-rent-message-template').subject.replace(/\\/, '') :
      (messageType === 'tenantPortalMessage' && firstDraft) ?
      MessaginTemplateContent.find((template)=> template['template-identifier'] === 'tenant-portal-invite-message-template').subject.replace(/\\/, '')
      :
      showUtilityBillingMessages && firstDraft ? 
        currentBillingPeriod ? 
          `<span>Betriebskostenabrechnung ${dayjs(currentBillingPeriod.startDate).format('DD.MM.YYYY')} - ${dayjs(currentBillingPeriod.endDate).format('DD.MM.YYYY')}</span>` 
          : MessaginTemplateContent.find((template)=> template['template-identifier'] === 'utility-billing-message-template').subject.replace(/\\/, '') 
      : messageData.subject ? 
          messageData.subject 
          : "",
      body: (messageType === 'tenantRentMessage' && firstDraft) ? MessaginTemplateContent.find((template)=> template['template-identifier'] === 'tenant-rent-message-template').body.replace(/\\/, '') : 
      (messageType === 'tenantPortalMessage' && firstDraft) ?
      MessaginTemplateContent.find((template)=> template['template-identifier'] === 'tenant-portal-invite-message-template').body.replace(/\\/, '')//.replace('<a href="#" target="_self">Zugangslink</a>', `<a href=\"${accessLink}\" target=\"_self\">${accessLink}</a>`)
      :
      replyAll ? "" : showUtilityBillingMessages && firstDraft ? MessaginTemplateContent.find((template)=> template['template-identifier'] === 'utility-billing-message-template').body.replace(/\\/, '') : messageData.body ? messageData.body : "",
      status,
      templateType: showUtilityBillingMessages ? 'BillingStatement' : rentalPaymentId ? 'RentalPayment' : (messageType === 'Tenant' || messageType === 'tenantPortalMessage') ? 'Tenant' : 'General',
      //rentalPaymentId: rentalPaymentId
      //messageType: showUtilityBillingMessages ? 'utilityBilling' : rentalPaymentId ? 'rentDue' : 'all'
    };
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_reg_api_baseurl + 'messages',
      'POST',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handlePostMessages',
      {payload, firstDraft},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handlePostMessages(response){
    const {messageType, recipients, showUtilityBillingMessages, setCurrentMessagesData} = this.props;
    const tenantId = this.props.history.location.state ? this.props.history.location.state.tenantId !== undefined ? this.props.history.location.state.tenantId : null : null;
    if(response.status && response.statuscode === 200){
      this.setState({messageData: response.result, messageDataOldState: response.result},()=> {
        response.result.subject && this.htmlToDraftConversion(response.result.subject);
        this.setState({messageSavingLoader: false}, ()=> setTimeout(() => {
          this.setState({messageSavingStatusPopper: false});
        }, 1000));
        !response.other.firstDraft && showNotification({
          message: 'Die Änderungen wurden übernommen.',
          icon: <img src={GreenTick} alt='green-tick'/>,
          id: 'autoSaveMessageData'
        })
      });
      if(showUtilityBillingMessages){
        this.postMessageRecipients(response.result.id, response.other.firstDraft);
      }
      if(recipients){
        this.postMessageRecipients(response.result.id, response.other.firstDraft);
      }
      if(messageType === 'tenantRentMessage'){
        this.postMessageRecipients(response.result.id, response.other.firstDraft);
      }
      if(messageType === 'tenantPortalMessage'){
        this.postMessageRecipients(response.result.id, response.other.firstDraft);
      }
      if(tenantId && messageType === 'Tenant'){
        this.postMessageRecipients(response.result.id, response.other.firstDraft);
      }
      response.other.firstDraft && this.getMessagePostalFee();
      
      setCurrentMessagesData({...response.result, templateType: messageType === 'tenantPortalMessage' ? 'tenantPortalMessage' : response.result.templateType});
    }
  }

  postMessageRecipients=(messageId, firstDraft=false)=>{
    const {rentalPaymentId=null} = this.props;
    const {allRecipients, tenants} = this.state;
    const tenantId = this.props.history.location.state ? this.props.history.location.state.tenantId !== undefined ? this.props.history.location.state.tenantId : null : null;
    const multipleTenants = this.props.history.location.state ? this.props.history.location.state.multipleTenants !== undefined ? this.props.history.location.state.multipleTenants : [] : [];
    let tenantObject = (tenantId && firstDraft) ? tenants.find((tenant)=> tenant.id === tenantId) !== undefined ? tenants.find((tenant)=> tenant.id === tenantId) : null : null;
    let tenantsArray = (multipleTenants && firstDraft) ? tenants.filter((tenant)=> multipleTenants.find((mTenant)=> mTenant.userId === tenant.id) !== undefined) : null;
    //if(allRecipients.length > 0){
      console.log('tenantObject: ', tenantObject);
      let payload= 
      tenantsArray.length > 0 ?
      tenantsArray.map((tenant)=> {
        return {
          messageId,
          tenantId: tenant.id,
          //contactId,
          name: tenant.name,
          email: tenant.email,
          street: tenant.street,
          zipCode: tenant.zipCode,
          city: tenant.city,
          isEmailEnabled: tenant.email ? true : tenant.isEmailEnabled,
          isPostEnabled: (tenant.street && tenant.zipCode && tenant.city) ? true : tenant.isPostEnabled,
          isHiddenForTenantPortal: tenant.isHiddenForTenantPortal,
          rentalPaymentId
        };
      })
      :
      tenantObject ?
      [{
        messageId,
        tenantId: tenantObject.id,
        name: tenantObject.name,
        email: tenantObject.email,
        street: tenantObject.street,
        zipCode: tenantObject.zipCode,
        city: tenantObject.city,
        isEmailEnabled: tenantObject.email ? true : tenantObject.isEmailEnabled,
        isPostEnabled: (tenantObject.street && tenantObject.zipCode && tenantObject.city) ? true : tenantObject.isPostEnabled,
        isHiddenForTenantPortal: tenantObject.isHiddenForTenantPortal,
        rentalPaymentId
      }]
      :
      firstDraft ?
      tenants.map((tenant)=> {
        return {
          messageId,
          tenantId: tenant.id,
          //contactId,
          name: tenant.name,
          email: tenant.email,
          street: tenant.street,
          zipCode: tenant.zipCode,
          city: tenant.city,
          isEmailEnabled: tenant.email ? true : tenant.isEmailEnabled,
          isPostEnabled: (tenant.street && tenant.zipCode && tenant.city) ? true : tenant.isPostEnabled,
          isHiddenForTenantPortal: tenant.isHiddenForTenantPortal,
          rentalPaymentId
        };
      })
      :
      allRecipients.map((recipient)=> {
        return {
          messageId,
          tenantId: recipient.id,
          //contactId,
          email: recipient.email,
          street: recipient.street,
          zipCode: recipient.zipCode,
          city: recipient.city,
          isEmailEnabled: recipient.isEmailEnabled,
          isPostEnabled: recipient.isPostEnabled,
          isHiddenForTenantPortal: recipient.isHiddenForTenantPortal,
          rentalPaymentId
        };
      });
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.user_reg_api_baseurl + 'messageRecipients',
        'POST',
        payload,
        null,
        false,
        true,
        false,
        this,
        'handlePostMessageRecipients',
        payload,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    //}
  }
  handlePostMessageRecipients(response){
    if(response.status && response.statuscode === 200){
      if(response.result.length > 0){
        let allRecipients = response.result.map((recipient)=> { 
          console.log('recipient.name: ', recipient.name);
          //if(this.state.allRecipients.find((recipient2)=> recipient2.id === recipient.id) === undefined){
            if(recipient.tenantId !== null){
              let ten = this.state.tenants.find((tenant)=> tenant.id === recipient.tenantId)
              if(ten !== undefined){
                return {...ten, ...recipient, id: recipient.id, name: recipient.name ? recipient.name : ten.name, value: recipient.id, label: ten.name} 
              }else{
                return {...recipient, id: recipient.id, value: recipient.tenantId, label: recipient.name} 
              }
            }else{
              return {...recipient, id: recipient.id, value: recipient.contactId, value: recipient.id, label: recipient.name}
            }
          //}
        });
        this.setState({allRecipients, allRecipientsOldState: allRecipients, selectedRecipients: response.result.sort((a,b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map((recipient)=> recipient.id)}, ()=> this.getMessagePostalFee());
      }
      //Todo: uncomment once backend changes implemented related to rentIcome messages.
      //this.props.history.replace({state: {}});
    }
  }

  putMessageRecipients=(messageId)=>{
    const {allRecipients} = this.state;
    if(allRecipients.length > 0){
      let payload= allRecipients.map((recipient)=> {
        return {
          ...recipient,
          messageId,
          tenantId: recipient.id,
          //contactId,
          name: recipient.name,
          isHiddenForTenantPortal: false,
          email: recipient.email,
          street: recipient.street,
          zipCode: recipient.zipCode,
          city: recipient.city,
          isEmailEnabled: recipient.isEmailEnabled,
          isPostEnabled: recipient.isPostEnabled
        };
      });
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.user_reg_api_baseurl + 'messageRecipients',
        'PUT',
        payload,
        null,
        false,
        true,
        false,
        this,
        'handlePutMessageRecipients',
        payload,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    }
  }
  handlePutMessageRecipients(response){
    if(response.status && response.statuscode === 200){
      
    }
  }

  getMessageRecipientsByMessageId=(id)=>{
    const {messageData} = this.state;
    if(messageData.id){
      this.setState({messageRecipientsLoader: true});
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.user_reg_api_baseurl + 'messageRecipients?messageIds='+ id,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleGettMessageRecipients',
        id,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        },
        true
      );
    }
  }

  handleGettMessageRecipients(response){
    console.log('handleGettMessageRecipients: ', response);
    if(response.status && response.statuscode === 200){
      if(response.result.length > 0){
        let allRecipients = response.result.map((recipient)=> { 
          console.log('recipient.name: ', recipient.name);
          //if(this.state.allRecipients.find((recipient2)=> recipient2.id === recipient.id) === undefined){
            if(recipient.tenantId !== null){
              let ten = this.state.tenants.find((tenant)=> tenant.id === recipient.tenantId);
              if(ten !== undefined){
                if(((recipient.email === null || recipient.email.length === 0) && ten.email) || ten.name !== recipient.name){
                  this.updateRecipient({...recipient, email: ten.email, name: ten.name});
                }
                return {...ten, ...recipient, id: recipient.id, name: recipient.name !== ten.name ? ten.name : (recipient.name ? recipient.name : ten.name), value: recipient.id, label: ten.name, email: recipient.email ? recipient.email : ten.email ? ten.email : null}
              }else{
                return {...recipient, id: recipient.id, value: recipient.tenantId, label: recipient.name} 
              }
            }else{
              return {...recipient, id: recipient.id, value: recipient.contactId, value: recipient.id, label: recipient.name}
            }
          //}
        });
        this.setState({allRecipients, allRecipientsOldState: allRecipients, selectedRecipients: response.result.sort((a,b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map((recipient)=> recipient.id), messageRecipientsLoader: false}, ()=> this.getMessagePostalFee());
      }
    }
    // else if(response.statuscode === 429){
    //   this.setState({messageRecipientsLoader: true});
    //   setTimeout(() => {
    //     this.getMessageRecipientsByMessageId(response.other);  
    //   }, 3000);
      
    // }
    else if(response.statuscode !== 429){
      this.setState({messageRecipientsLoader: false}, ()=> this.getMessagePostalFee());
    }
  }

  updateRecipient=(recipientData)=>{
    let payload = recipientData;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_reg_api_baseurl + 'messageRecipients/'+ recipientData.id,
      'PUT',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handlePutMessageRecipients',
      payload,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handlePutMessageRecipients(response){

  }

  getUBMessagesByUtilityBillingId = () => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_reg_api_baseurl + `messages/GetAllByUtilityBilling?utilityBillingId=${this.props.currentDataId}&messageStatus=Draft`,//GetAllByAccount
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetMessages',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handleGetMessages(response){
    const {messageType, setCurrentMessagesData} = this.props;
    if(response.status && response.statuscode === 200){
      if(response.result.length > 0){
        let messageData= response.result[0];//.find((msgDt)=> msgDt.utilityBillingId === this.props.currentDataId);
        if(messageData){
          this.setState({messageData, messageDataOldState: messageData},()=> {
            messageData.subject && this.htmlToDraftConversion(messageData.subject);
            this.getMessageRecipientsByMessageId(messageData.id);
            this.getBillingStatements();
          });
        }
      }
      setCurrentMessagesData({...response.result, templateType: messageType === 'tenantPortalMessage' ? 'tenantPortalMessage' : response.result.templateType});
    }else if(response.message === 'Not Found'){
      console.log('handleGetMessages: ', response);
      this.postMessages('Draft', true);
    }
  }

  getMessagesById = (id) => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_api_baseurl + '/messages/'+id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetMessagesById',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handleGetMessagesById(response){
    if(response.status && response.statuscode === 200){

    }
  }

  putMessagesById = () => {
    this.setState({messageSavingLoader: true, messageSavingStatusPopper: true});
    let payload= this.state.messageData;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_reg_api_baseurl + 'messages/'+this.state.messageData.id,
      'PUT',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handlePutMessagesById',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handlePutMessagesById(response){
    const {messageType, setCurrentMessagesData} = this.props;
    if(response.status && response.statuscode === 200){
      this.setState({messageData: response.result, messageDataOldState: response.result}, ()=> {
        this.setState({messageSavingLoader: false}, ()=> setTimeout(() => {
          this.setState({messageSavingStatusPopper: false});
        }, 1000));
        showNotification({
          message: 'Die Änderungen wurden übernommen.',
          icon: <img src={GreenTick} alt='green-tick'/>,
          id: 'autoSaveMessageData'
        });
        if(this.state.allRecipients.length > 0){
          //this.putMessageRecipients(response.result.id);
        }else{
          //this.postMessageRecipients(response.result.id);
        }
      });

      setCurrentMessagesData({...response.result, templateType: messageType === 'tenantPortalMessage' ? 'tenantPortalMessage' : response.result.templateType});
    }
  }

  replaceAllPlaceHolders=(html)=>{
    let newHtml= html;

    newHtml= newHtml.replaceAll('<a href="#" target="_self">Abrechnungszeitraum</a>', '{{TENANT_OCCUPATION_BILLING_PERIOD}}');
    newHtml= newHtml.replaceAll('<a href="#" target="_self">Betriebskosten</a>', '{{TOTAL_ALLOCATED_UTILITY_COSTS}}');
    newHtml= newHtml.replaceAll('<a href="#" target="_self">Guthaben / Nachzahlung</a>', '{{BILLING_STATEMENT_BALANCE}}');
    newHtml= newHtml.replaceAll('<a href="#" target="_self">Empfänger</a>', '{{RECIPIENT_NAME}}');
    newHtml= newHtml.replaceAll('<a href="#" target="_self">Mieteinheit</a>', '{{RENTAL_UNIT_NAME}}');
    newHtml= newHtml.replaceAll('<a href="#" target="_self">Objektadresse</a>', '{{PROPERTY_STREET}}, {{PROPERTY_ZIPCODE}} {{PROPERTY_CITY}}');
    newHtml= newHtml.replaceAll('<a href="#" target="_self">Vorauszahlungen</a>', '{{TOTAL_ADVANCE_PAYMENTS}}');

    //new
    newHtml= newHtml.replaceAll('<a href="#" target="_self">Mieter</a>', '{{TENANT_NAME}}');
    newHtml= newHtml.replaceAll('<a href="#" target="_self">Absender</a>', '{{USER_FIRST_NAME}} {{USER_LAST_NAME}}');
    newHtml= newHtml.replaceAll('<a href="#" target="_self">Auszugsdatum</a>', '{{TENANT_MOVE_OUT_DATE}}');
    newHtml= newHtml.replaceAll('<a href="#" target="_self">Einzugsdatum</a>', '{{TENANT_MOVE_IN_DATE}}');
    newHtml= newHtml.replaceAll('<a href="#" target="_self">erhaltene Miete</a>', '{{RENTAL_PAYMENT_RECEIVED_RENT}}');
    newHtml= newHtml.replaceAll('<a href="#" target="_self">gesamte ausstehende Miete</a>', '{{RENTAL_PAYMENT_EXPECTED_RENT_TOTAL}}');
    newHtml= newHtml.replaceAll('<a href="#" target="_self">Kaltmiete</a>', '{{COLD_RENT}}');
    newHtml= newHtml.replaceAll('<a href="#" target="_self">Mietmonat</a>', '{{RENTAL_PAYMENT_MONTH}}');
    newHtml= newHtml.replaceAll('<a href="#" target="_self">Vermieter</a>', '{{ACCOUNT_COMPANY_NAME}}');
    newHtml= newHtml.replaceAll('<a href="#" target="_self">Vorauszahlung</a>', '{{RENT_PRE_PAYMENTS}}');
    newHtml= newHtml.replaceAll('<a href="#" target="_self">Warmmiete</a>', '{{WARM_RENT}}');

    newHtml= newHtml.replaceAll('<a href="#" target="_self">Zugangslink</a>', '{{ACCESS_LINK}}');
    
    
    newHtml= newHtml.replaceAll('<a href="" target="_self">Abrechnungszeitraum</a>', '{{TENANT_OCCUPATION_BILLING_PERIOD}}');
    newHtml= newHtml.replaceAll('<a href="" target="_self">Betriebskosten</a>', '{{TOTAL_ALLOCATED_UTILITY_COSTS}}');
    newHtml= newHtml.replaceAll('<a href="" target="_self">Guthaben / Nachzahlung</a>', '{{BILLING_STATEMENT_BALANCE}}');
    newHtml= newHtml.replaceAll('<a href="" target="_self">Empfänger</a>', '{{RECIPIENT_NAME}}');
    newHtml= newHtml.replaceAll('<a href="" target="_self">Mieteinheit</a>', '{{RENTAL_UNIT_NAME}}');
    newHtml= newHtml.replaceAll('<a href="" target="_self">Objektadresse</a>', '{{PROPERTY_STREET}}, {{PROPERTY_ZIPCODE}} {{PROPERTY_CITY}}');
    newHtml= newHtml.replaceAll('<a href="" target="_self">Vorauszahlungen</a>', '{{TOTAL_ADVANCE_PAYMENTS}}');

    //new
    newHtml= newHtml.replaceAll('<a href="" target="_self">Mieter</a>', '{{TENANT_NAME}}');
    newHtml= newHtml.replaceAll('<a href="" target="_self">Absender</a>', '{{USER_FIRST_NAME}} {{USER_LAST_NAME}}');
    newHtml= newHtml.replaceAll('<a href="" target="_self">Auszugsdatum</a>', '{{MOVE_OUT_DATE}}');
    newHtml= newHtml.replaceAll('<a href="" target="_self">Einzugsdatum</a>', '{{MOVE_IN_DATE}}');
    newHtml= newHtml.replaceAll('<a href="" target="_self">erhaltene Miete</a>', '{{RENTAL_PAYMENT_RECEIVED_RENT}}');
    newHtml= newHtml.replaceAll('<a href="" target="_self">gesamte ausstehende Miete</a>', '{{RENTAL_PAYMENT_EXPECTED_RENT_TOTAL}}');
    newHtml= newHtml.replaceAll('<a href="" target="_self">Kaltmiete</a>', '{{COLD_RENT}}');
    newHtml= newHtml.replaceAll('<a href="" target="_self">Mietmonat</a>', '{{RENTAL_PAYMENT_MONTH}}');
    newHtml= newHtml.replaceAll('<a href="" target="_self">Vermieter</a>', '{{ACCOUNT_COMPANY_NAME}}');
    newHtml= newHtml.replaceAll('<a href="" target="_self">Vorauszahlung</a>', '{{RENT_PRE_PAYMENTS}}');
    newHtml= newHtml.replaceAll('<a href="" target="_self">Warmmiete</a>', '{{WARM_RENT}}');

    newHtml= newHtml.replaceAll('<a href="" target="_self">Zugangslink</a>', '{{ACCESS_LINK}}');

    console.log('newMessageData: ', newHtml);
    //setNewMessageBody(newHtml);
    return newHtml;
  }

  putPublishMessagesById = () => {
    let payload= this.state.messageData;
    payload.body= this.replaceAllPlaceHolders(payload.body);
    payload.subject= this.replaceAllPlaceHolders(payload.subject);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_reg_api_baseurl + 'messages/'+this.state.messageData.id,
      'PUT',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handleFinalPutMessagesById',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handleFinalPutMessagesById(response){
    if(response.status && response.statuscode === 200){
      this.getMessagePostalFee();
      var elem = document.getElementById("publishMessage");
      this.props.setPublishMessageId(this.state.messageData.id);
      loader(true);
      setTimeout(() => {
        if (typeof elem.onclick == "function") {
          elem.onclick.apply(elem);
          this.setState({inPublishProcess: true});
          showNotification({
            message: 'Der Versandprozess wurde gestartet.',
            icon: <img src={GreenTick} alt='green-tick'/>
          });
          this.props.setUtilityBillingData([]);
          loader(false);
          this.props.history.push('/messaging');
        }  
      }, 1000);
      // Autoload.HttpRequestAxios.HttpRequest(
      //   Autoload.Setting.user_reg_api_baseurl + 'messages/'+this.state.messageData.id+'/publish',
      //   'PUT',
      //   null,
      //   null,
      //   false,
      //   true,
      //   false,
      //   this,
      //   'handlePutPublishMessagesById',
      //   null,
      //   false,
      //   '',
      //   {
      //     Authorization: Autoload.Utils.getAuthToken(),
      //   }
      // );
    }else{
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='green-tick'/>
      });
      this.setState({openMessageConfirmationModal: false});
    }
  }

  handlePutPublishMessagesById(response){
    if(response.status && response.statuscode === 200){
      showNotification({
        message: 'Der Versandprozess wurde gestartet.',
        icon: <img src={GreenTick} alt='green-tick'/>
      });
      this.props.setUtilityBillingData([]);
      this.props.history.push('/messaging');
    }else{
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='green-tick'/>
      });
    }
    this.setState({openMessageConfirmationModal: false});
  }

  deleteMessagesById = (id, stopGoBackToMessages=false) => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_reg_api_baseurl + 'messages/'+id,
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleDeleteMessagesById',
      {stopGoBackToMessages},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handleDeleteMessagesById(response){
    if(response.status && response.statuscode === 200){
      if(window.location.href.includes('ub-step=billing-statements-sending')){
        this.props.goBack();
      }else if(response.other.stopGoBackToMessages === false){
        this.props.history.push('/messaging');
      }
      
    }
  }

  getMessagePostalFee=()=>{
    const {numberBillingStatementTableRows, numberMeterReadingsTableRows, showUtilityBillingMessages, utilityBillingData, currentDataId} = this.props;
    let ub = utilityBillingData?.find((ub)=> ub.id === currentDataId);
    console.log('getMessagePostalFee_ub: ', ub);
    let billingStatementRows = numberBillingStatementTableRows ? numberBillingStatementTableRows : this.state.numberBillingStatementTableRows;
    let billingStatementPages = showUtilityBillingMessages ? (billingStatementRows > 32 ? (Math.ceil((billingStatementRows - 32)/44) + 1) : 1) : 0;
    let meterReadingsPages = showUtilityBillingMessages ? !ub?.isMeterReadingAndConsumptionInfoHidden ? (numberMeterReadingsTableRows > 44 ? (Math.ceil((numberMeterReadingsTableRows - 44)/44) + 1) : 1) : 0 : 0;
    let heatingInformationPages = showUtilityBillingMessages ? ub?.heatingCostsActivated ? (!ub?.isCostSplitWarmWaterAndHeatingCostInfoHidden || !ub?.isHeatingCostInfoHidden || !ub?.isSplitBaseConsumptionCostInfoHidden) ? 1 : 0 : 0 : 0;
    
    console.log('getMessagePostalFee: ', billingStatementPages, meterReadingsPages);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.user_reg_api_baseurl + 'messages/'+ this.state.messageData.id+'/PostalFee?pageCount='+ (billingStatementPages+meterReadingsPages+heatingInformationPages+1),
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetMessagePostalFee',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }
  handleGetMessagePostalFee(response){
    if(response.status && response.statuscode === 200){
      this.setState({postalFee: response.result});
    }
  }
  // messages end-points end
  // postal top-up start
  putTopUpCredit=(amount)=>{
    loader(true);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_api_baseurl + `${this.props.accountData.accountId}/rechargePostalFee?amount=${amount}`,
      'PUT',
      null,
      null,
      false,
      true,
      false,
      this,
      'handlePutTopUpCredit',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }
  handlePutTopUpCredit(response){
    if(response.status && response.statuscode === 200){
      showNotification({
        message: 'Das neue Guthaben wurde Ihrem Portoguthaben gutgeschrieben.',
        icon: <img src={GreenTick} alt='green-tick' />,
        id: 'postalTopUpSuccess'
      });
      this.getAccounts();
    }else{
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross' />,
        id: 'postalTopUpError'
      });
    }
    loader(false);
    this.setState({openMessagingPricingModal: false});
  }

  getAccounts=()=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.account_api_baseurl + this.props.accountData.accountId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetAccounts',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }
  handleGetAccounts(response){
    if(response.status && response.statuscode === 200){
      this.props.setAccountData(response.result);
    }
  }
  // postal top-up end
  htmlToDraftConversion=(data)=>{
    const contentBlock = htmlToDraft(data);
    if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState1 = EditorState.createWithContent(contentState);
        const editorState2 = EditorState.moveFocusToEnd(editorState1);
        this.onEditorStateChange(editorState2);
        return 'handled';
    }
  }

  onEditorStateChange= (editorState) => {
    console.log('editorState: ', editorState);

    this.setState({editorState, messageData: {...this.state.messageData, subject: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))}});
  };

  addPlaceHolderToSubject=(html)=>{
    const {editorState} = this.state;
    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
        // const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        // const editorState1 = EditorState.createWithContent(contentState);
        // const editorState2 = EditorState.moveFocusToEnd(editorState1);

        const newContent = Modifier.replaceWithFragment(
          currentContent,
          currentSelection,
          ContentState.createFromBlockArray(contentBlock.contentBlocks).getBlockMap()
        );
        var subjectEditor = document.getElementById('message-subject-editor');
        console.log('this.subjectEditorRef: ', this.subjectEditorRef);
        this.subjectEditorRef.props.onChange(EditorState.push(editorState, newContent, 'insert-characters'));
    }
  }

  getBillingStatements=()=>{
    this.setState({loader: true});
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'billingStatements?utilityBillingId='+this.props.currentDataId,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleGetBillingStatements',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
    );
  }

  handleGetBillingStatements(response){
    if(response.status && response.statuscode === 200){
        this.setState({allBillingStatements: response.result});
        if(response.result.length > 0){
          this.getCostAllocationsForBillingStatements(response.result[0].id);
        }
    }
  }

  

  getCostAllocationsForBillingStatements=(id)=>{
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'costAllocations/forBillingStatement?utilityBillingId='+this.props.currentDataId+'&billingStatementId='+id,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleGetCostAllocationsForBillingStatements',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
    );
  }

  handleGetCostAllocationsForBillingStatements(response){
    if(response.status && response.statuscode === 200){
        this.setState({numberBillingStatementTableRows: response.result.length}, ()=> this.getMessagePostalFee());
    }
  }
  
  sendMessageEvent=()=> {
    const {allRecipients, messageData, postalFee, isMobile} = this.state;
    const {accountData, propertySelected} = this.props;

    if(allRecipients.length === 0){
      showNotification({
        message: 'Bitte wählen Sie mindestens einen Empfänger aus, um fortzufahren.', 
        icon: <img src={RedCross} />
      })
    }else if(!accountData?.addressCompany || !accountData?.addressStreet || !accountData?.addressZipCode || !accountData?.addressCity || !accountData?.companyEmail || !accountData?.companyPhoneNumber){
      showNotification({
        message: <span>Die Absenderinformationen oder Absender-E-Mail ist unvollständig. Bitte ergänzen Sie die Kontaktdaten im <span style={{color: '#5555FF', cursor: 'pointer'}} onClick={()=> this.props.history.push('/profile/companyDetails')}>Nutzerkonto</span>.</span>,
        icon: <img src={RedCross} alt='red-cross' />,
        autoClose: false,
        id: 'accountInformationIncompleteError'
      });
    }
    else if((messageData.body.includes('<a href="#" target="_self">Objektadresse</a>') || messageData.body.includes('<a href="" target="_self">Objektadresse</a>') || messageData.subject.includes('<a href="#" target="_self">Objektadresse</a>') || messageData.subject.includes('<a href="" target="_self">Objektadresse</a>')) && (!propertySelected.propertyAddress || !propertySelected.propertyCity || !propertySelected.propertyZipCode)){
      showNotification({
        message: 
        <div>
          <div><span>Die Informationen für den Platzhalter Objektadresse sind noch nicht vollständig. Bitte ergänzen Sie die Informationen oder entfernen Sie den Platzhalter.</span></div>
          <div><span style={{color: '#5555FF', cursor: 'pointer'}} onClick={()=> this.props.history.push('/property')}>Objektadresse ergänzen</span><img src={RightArrowPartner} style={{width: '12px', marginLeft: '4px'}}/></div>
        </div>,
        icon: <img src={RedCross} alt='red-cross' />,
        autoClose: false,
        id: 'propertyAddressInformationIncomplete'
      });
    }else if(accountData.accountPostalCredit < postalFee.totalPostalFee){
      showNotification({
        message: <span>Ihr aktuelles Portoguthaben reicht nicht aus, um diese Nachricht zu versenden. Bitte laden Sie Ihr Portoguthaben auf. <span style={{color: '#5555FF', cursor: 'pointer'}} onClick={()=> this.setState({openMessagingPricingModal: true}, ()=> hideNotification('creditAmountLessThanExpectedAmountError'))}>Portoguthaben aufladen<img src={RightArrowPartner} style={{width: '12px', marginLeft: '4px'}}/></span></span>,
        icon: <img src={RedCross} alt='red-cross' />,
        id: 'creditAmountLessThanExpectedAmountError'
      })
    }
    else{
      console.log('allRecipient_senden: ', allRecipients);
      let recipientsWithError = allRecipients.filter((recipient)=> recipient.isEmailEnabled === false && recipient.isPostEnabled === false && recipient.isHiddenForTenantPortal === true)
      if(recipientsWithError.length > 0){
        showNotification({
          message: 
          <Stack spacing={0}>
            <span>{`Bitte wählen Sie für die nachfolgenden Empfänger mindestens eine Versandmethode (Email, Brief, Mieterportal) aus, um fortzufahren: ${recipientsWithError.sort((a,b)=> a.name.localeCompare(b.name)).map((recipient, index)=> ` ${recipient.name}`)}`}</span>
          </Stack>,
          icon: <img src={RedCross} alt='red-cross'/>,
          id: 'recipientsWithError'
        })
      }else{
        this.setState({openMessageConfirmationModal: true});
      }
    }
  }
  renderSendButton=()=>{
    const {allRecipients, messageData, postalFee, isMobile} = this.state;
    const {accountData, propertySelected} = this.props;
    return (isMobile ?
      <SendIcon
      style={{
        cursor: 'pointer',
        color: '#5555FF',
        fontSize: '18px'
      }}
      onClick={()=> this.sendMessageEvent()}/>
      :
      <ColorButton
      text={'Senden'}
      className={`purple-btn ${(allRecipients.length === 0 || !accountData?.addressCompany || !accountData?.addressStreet || !accountData?.addressZipCode || !accountData?.addressCity || !accountData?.companyEmail) && 'disabled'}`}
      onClick={()=> this.sendMessageEvent()}
      />
    )
  }

  renderAccountMessageBalanceAndEstimation=()=>{
    const {postalFee, openPostalFeeStatus, isMobile, hoverOnStarter2} = this.state;
    const {accountData, currentSubscription} = this.props;
    return(
      <Stack align={isMobile ? 'flex-start' : 'flex-end'} spacing={3} style={{marginRight: '2px', marginTop: !isMobile && '13.5px'}}>
        {isMobile && <lable>Portokosten</lable>}
        { <Popover
        opened={postalFee && openPostalFeeStatus}
        withArrow
        placement='start'
        position={isMobile ? 'bottom' : 'left'}
        target={
          <Group 
          noWrap 
          style={{fontWeight: '400', color: '#13135C', fontSize: '12px', cursor: 'pointer'}}
          onMouseEnter={() => this.setState({openPostalFeeStatus: true})} 
          onMouseLeave={() => this.setState({openPostalFeeStatus: false})}
          onTouchStart={() => this.setState({openPostalFeeStatus: !openPostalFeeStatus})}
          >Voraussichtliche Portokosten: {postalFee ? checkProperty(postalFee, 'totalPostalFee', null) ? formatNumber(postalFee.totalPostalFee, true) : '0,00 €' : '0,00 €'}</Group>
        }>
          {postalFee && <Group style={{width: '250px', fontSize: '12px', fontWeight: '400'}}><span><span style={{fontWeight: 'bold'}}>Voraussichtliche Portokosten: {postalFee ? checkProperty(postalFee, 'totalPostalFee', null) ? formatNumber(postalFee.totalPostalFee, true) : '0,00 €' : '0,00 €'}</span><br/>({postalFee.recipientCount} {postalFee.name === 'Standard Letter' ? 'Standard-Briefe' : postalFee.name === 'Compact Letter' ? 'Kompakt-Briefe' : postalFee.name === 'Large Letter' ? 'Groß-Briefe' : ''}{postalFee.additionalPageCount > 0 && ` mit ${postalFee.additionalPageCount} Zusatzseite(n) je ${formatNumber(postalFee.fee, true)}`}).<br/>Die tatsächlichen Portokosten werden bei Übermittlung an die Deutsche Post ermittelt.</span></Group>}
        </Popover>}
        
        <Group noWrap style={{fontWeight: '400', color: '#13135C', fontSize: '12px'}}>Portoguthaben: {accountData ? checkProperty(accountData, 'accountPostalCredit', null) ? formatNumber(accountData.accountPostalCredit, true) : '0,00 €' : '0,00 €'}</Group>
        <Group noWrap style={{fontSize: '12px', fontWeight: '400', color: '#5555FF', cursor: 'pointer'}} spacing={5} onClick={()=> this.setState({openMessagingPricingModal: true})}>Portoguthaben aufladen <img src={RightArrowPartner} style={{marginTop: '2px', width: '12px'}}/></Group>

        {!isMobile &&
        <Group noWrap spacing={0}>
          <Popover
          opened={currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' && hoverOnStarter2}
          onClose={() => this.setState({hoverOnStarter2: false})}
          position="top"
          placement="center"
          withArrow
          trapFocus={false}
          closeOnEscape={false}
          transition="pop-top-left"
          width={320}
          target={
          <Group onMouseEnter={()=> this.setState({hoverOnStarter2: true})} noWrap spacing={0}>
            <Group noWrap style={{fontSize: '12px', fontWeight: '400', color: '#5555FF', cursor: 'pointer', marginTop: '10px'}} spacing={5} onClick={()=> currentSubscription?.subscriptionKey.split('_')[0] !== 'STARTER' && this.setState({openBulkMessageDownloadModal: true})}>Alle herunterladen (PDF) <img src={RightArrowPartner} style={{marginTop: '2px', width: '12px'}}/></Group>
          </Group>
          }
          >
            <Stack style={{padding: '10px'}} spacing={15}>
              <Group noWrap>
                <span style={{color: 'rgb(85, 85, 255)', fontSize: '1rem', whiteSpace: 'nowrap'}}>Alle Abrechnungen herunterladen (PDF)</span>
              </Group>
              <Group style={{justifyContent: 'center'}} noWrap>
                <img src={GoldenLockIcon} width='18px'/>
                <span style={{color: '#5f5f7a', fontSize: '11px', fontWeight: '400'}}>Abrechnungen mit Anschreiben für alle Mieter mit einem Klick als PDF herunterladen - mit hellohousing <span onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})} style={{textDecoration: 'underline', fontWeight: '500', cursor: 'pointer'}}>Premium</span>.</span>
              </Group>
              <Group>
                <ColorButton
                text={currentSubscription ? (currentSubscription?.subscriptionName === 'Starter' ? (accountData.isPremiumPlanTested === false ? 'Jetzt freischalten' : 'Jetzt freischalten') : currentSubscription?.subscriptionName === 'Premium' ? (accountData.isProfessionalPlanTested === false ? 'Jetzt freischalten' : 'Jetzt freischalten') : 'Jetzt freischalten') : 'Jetzt freischalten'}
                onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})}
                className={'purple-btn'}
                />
              </Group>
            </Stack>
          </Popover>
        </Group>}
      </Stack>
    )
  }

  renderMessagingButtons=()=>{
    const {allRecipients, messageData, postalFee, openPostalFeeStatus, isMobile, hoverOnStarter2} = this.state;
    const {accountData, propertySelected, currentSubscription} = this.props;
    return(
      <Stack align={'flex-end'} spacing={5}>
        <Group spacing={10} noWrap>
          <Group spacing={5} noWrap>
            <div>
              {isMobile ?
              <Group>
                <RecordOptions
                showEditButton={false}
                showDeleteButton={false}
                listOfItems={[
                    {
                      text: <span style={{color: '#E8193C'}}>Löschen</span>,
                      className: 'receipts-main-menu-dropdown-item',
                      leftImage: <DeleteIcon style={{fontSize: '15px', color: '#E8193C'}}/>,
                      openFunction: (()=> this.setState({openMessageDeletionModal: true}))
                    },
                    {
                      text: 'Vorschau',
                      className: 'receipts-main-menu-dropdown-item',
                      leftImage: <VisibilityIcon
                      style={{
                        cursor: 'pointer',
                        color: '#5555FF',
                        fontSize: '15px',
                        //opacity: 0.4
                      }}/>,
                      openFunction: (()=> this.setState({openPreviewModal: true})),
                      disabled: allRecipients.length === 0
                    },
                    {
                      text: 'Alle herunterladen',
                      className: 'receipts-main-menu-dropdown-item',
                      leftImage: <img src={PdfIcon} width={'15px'} />,
                      openFunction: (()=> currentSubscription?.subscriptionKey.split('_')[0] !== 'STARTER' && this.setState({openBulkMessageDownloadModal: true}))
                    }
                ]}
                style={{marginRight: '0px'}}
                buttonStyle={{border: 'none', background: 'transparent'}}
                />
                {isMobile && this.renderSendButton()}
              </Group>
              :
              <RecordOptions
              className="delete-message"
              showEditButton={false}
              toggleDeleteModal={() => this.setState({openMessageDeletionModal: true})}
              showLockButton={false}
              //showPrintButton={messageData.utilityBillingId !== null}
              //onPrint={()=> this.setState({openBulkMessageDownloadModal: true})}
              //printButtonText={'Alle herunterladen (PDF)'}
              buttonStyle={{background: 'none', border: 'none', minWidth: '37px'}}
              />}
            </div>
            {!isMobile &&
            <div>
              <ColorButton
              text={'Vorschau'}
              className={'gray-btn'}
              onClick={()=> this.setState({openPreviewModal: true})}
              disabled={allRecipients.length === 0}
              />
            </div>}
          </Group>
          <div>
            {!isMobile && this.renderSendButton()}
          </div> 
          {/* <Group>
            <Tooltip title="Drucken">
              <IconButton 
              aria-label="filter list" 
              onClick={() => null}
              style={{
                background: 'white', 
                border: '1px solid #DADAE5', 
                boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
                padding: '5px'
                }}>
                <img
                src={PrintIconBlue} 
                style={{
                  width: '15px',
                  height: '15px',
                  }}/>
              </IconButton>
            </Tooltip>
          </Group> */}
        </Group>
        
        {!isMobile && this.renderAccountMessageBalanceAndEstimation()}
      </Stack>
    )
  }

  evaluateRentalPayments=()=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalPayments/evaluateRentalPayments',
      'POST',
      {propertyId: this.props.propertySelected.id},
      null,
      false,
      true,
      false,
      this,
      'handleEvaluateRentalPayments',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleEvaluateRentalPayments(response){
    this.putPublishMessagesById();
  }

  render() {
    const multipleTenants = this.props.history.location.state ? this.props.history.location.state.multipleTenants !== undefined ? this.props.history.location.state.multipleTenants : [] : [];
    const {tenant, selectedRecipients, dropdownIcon, openRecipientsModal, openMessagingPricingModal, paymentDetails, allRecipients, messageData, tenants, tenantsData, messageRecipientsLoader, postalFee, openPreviewModal, messageSavingLoader, subjectInFocus, messageDataOldState, openPostalFeeStatus, openMessageConfirmationModal, openMessageDeletionModal, isMobile, openBulkMessageDownloadModal, messageSavingStatusPopper} = this.state;
    const {subject, body, status} = this.state.messageData;
    const {placeholders, accountData, showUtilityBillingMessages, billingPeriod, utilityBillingData, propertySelected, rentalPaymentId, messageType} = this.props;
    console.log('myRef: ', this.myRef);
    console.log('accountData?: ', accountData);
    console.log('selectedTenants_123: ', selectedRecipients);
    console.log('allRecipients: ', allRecipients);
    console.log('messageData: ', messageData);
    console.log('tenants, tenantsData: ', tenants, tenantsData);
    console.log('subject: ', messageData.subject, messageDataOldState.subject);
    console.log('utilityBillingData: ', utilityBillingData);
    console.log('propertySelected: ', propertySelected);
    console.log('isMobile: ', isMobile);
    return (
      <div>
        <Stack>
          <Group align={'flex-start'} position='apart'>
            <Stack spacing={15} style={{width: '100%'}}>
              {isMobile && 
              <div style={{position: 'absolute', top: 73, right: 12}}>
                {this.renderMessagingButtons()}
              </div>}
              
              <Group position='apart' align={'flex-start'}>
                <Stack spacing={15}>
                  <Stack spacing={2}>
                    <lable>Empfänger</lable>
                    <Group align={'center'} style={{width: '100%', justifyContent: 'space-between'}} noWrap>
                      <Group onClick={()=> this.setState({openRecipientsModal: true})} style={{width: !isMobile && '560.5px', minWidth: isMobile ? '100%' : '300px'}}>
                        <MultiSelect
                        data={allRecipients}
                        className={'multi-select-messages-tenants'}
                        //limit={20}
                        ref={this.myRef}
                        valueComponent={Value}
                        itemComponent={Item}
                        //searchable
                        onSearchChange={(e)=> console.log('onSearchChange: ', e)}
                        value={selectedRecipients}
                        placeholder="Empfänger bearbeiten"
                        onClick={()=> this.setState({openRecipientsModal: true})} 
                        //icon={<Loader size='sm' variant="dots"/>}
                        rightSection={messageRecipientsLoader && <Loader size='sm' variant='dots' />}
                        onDropdownOpen={()=> this.setState({dropdownIcon: Up})}
                        onDropdownClose={()=> this.setState({dropdownIcon: Down})}
                        style={{width: isMobile ? '100%' : '560.5px'}}
                        styles={{
                          //disabled: { background: 'white', cursor: 'pointer' },
                          input: {opacity: 1},
                          rightSection: {marginRight: '10px', display: !messageRecipientsLoader && 'none'},
                          dropdown: {display: 'none'}
                        }}
                        classNames={{
                          searchInput: 'tenant-selector-search-input',
                          input: selectedRecipients.length > 0 ? 'tenant-selector-input-with-values' : 'tenant-selector-input'
                        }}
                        onChange={(e)=> this.setState({selectedRecipients: e})}
                        {...this.props} 
                        />
                      </Group>
                      {!isMobile &&
                      <Group>
                        {<div style={{display: 'flex', alignItems: 'center'}}>
                        <Tooltip title="Empfänger hinzufügen" >
                          <IconButton 
                            aria-label="filter list"
                            onClick={()=> this.setState({openRecipientsModal: true})} 
                            style={{
                              background: '#5655FE',
                              border: '1px solid #DADAE5', 
                              boxShadow: '0 1px 2px 0 rgba(14, 14, 29, .06)', 
                              padding: '5px',
                              cursor: 'pointer'
                            }}>
                              <AddIcon 
                              style={{
                                color: 'white',
                                fontSize: '15px'
                                }}/>
                          </IconButton>
                        </Tooltip>
                        </div>}
                      </Group>}
                    </Group>
                  </Stack>
                  <Group noWrap>
                    <Stack spacing={5} style={{width: !isMobile && '560.5px', minWidth: isMobile ? '100px' : '300px'}}>
                        <lable>Absender</lable>
                        {
                        // (!accountData?.addressCompany || !accountData?.addressStreet || !accountData?.addressZipCode || !accountData?.addressCity || !accountData?.companyEmail) ?
                        // <Group style={{cursor: 'pointer'}} spacing={6} onClick={()=> {        
                        //   showNotification({
                        //     message: <span>Die Absenderadresse und Email ist unvollständig. Bitte ergänzen Sie die Kontaktdaten im <span style={{color: '#5555FF', cursor: 'pointer'}} onClick={()=> this.props.history.push('/profile/companyDetails')}>Nutzerkonto</span>.</span>,
                        //     icon: <img src={RedCross} alt='red-cross' />,
                        //     autoClose: false,
                        //     id: 'accountInformationIncompleteError'
                        //   })
                        // }}>
                        //   <img src={RedCross} alt='red-cross'/>
                        //   <span style={{color: '#dc3545', fontWeight: '500', fontSize: '12px'}}>Absenderadresse und Email unvollständig</span>
                        // </Group>
                        // :
                        <Stack style={{width: '100%'}} spacing={3}>
                          {/* <Input
                          style={{width: '100%'}}
                          placeholder=''
                          classNames={{
                            input: 'messaging-subject-input'
                          }}
                          onClick={()=> {
                            if(!accountData?.addressCompany || !accountData?.addressStreet || !accountData?.addressZipCode || !accountData?.addressCity || !accountData?.companyEmail){
                              showNotification({
                                message: <span>Die Absenderadresse oder Absender-E-Mail ist unvollständig. Bitte ergänzen Sie die Kontaktdaten im <span style={{color: '#5555FF', cursor: 'pointer'}} onClick={()=> this.props.history.push('/profile/companyDetails')}>Nutzerkonto</span>.</span>,
                                icon: <img src={RedCross} alt='red-cross' />,
                                autoClose: false,
                                id: 'accountInformationIncompleteError'
                              })
                            }
                          }}
                          value={`${accountData?.addressCompany}, ${accountData?.addressStreet}, ${accountData?.addressZipCode} ${accountData?.addressCity}, ${accountData?.companyEmail}`}
                          /> */}
                          <Group noWrap={!isMobile} spacing={5}>
                            <Group noWrap spacing={6} align='flex-start' style={{fontWeight: '400', color: 'rgb(19, 19, 92)', fontSize: '14px'}}>
                              <span>
                                Adresse: 
                                {(!accountData?.addressCompany || !accountData?.addressStreet || !accountData?.addressZipCode || !accountData?.addressCity || !accountData?.companyPhoneNumber) ?
                                <Group style={{cursor: 'pointer'}} noWrap={!isMobile} spacing={6} onClick={()=> {  
                                  hideNotification('accountInformationIncompleteError');      
                                  showNotification({
                                    message: <span>Die Absenderadresse ist unvollständig. Bitte ergänzen Sie die Kontaktdaten im <span style={{color: '#5555FF', cursor: 'pointer'}} onClick={()=> this.props.history.push('/profile/companyDetails')}>Nutzerkonto</span>.</span>,
                                    icon: <img src={RedCross} alt='red-cross' />,
                                    autoClose: false,
                                    id: 'accountInformationIncompleteError'
                                  })
                                }}>
                                  <img src={RedCross} alt='red-cross'/>
                                  <span style={{color: '#dc3545', fontWeight: '500', fontSize: '12px', whiteSpace: !isMobile && 'nowrap'}}>Absenderadresse unvollständig</span>
                                </Group>
                                :
                                <span>
                                  {/* Address */}
                                  {` ${accountData?.addressCompany+','} ${accountData?.addressStreet+','} ${accountData?.addressZipCode} ${accountData?.addressCity+','} ${accountData?.companyPhoneNumber+','} `}
                                  {/* Email */}
                                  <span>
                                    Email:
                                    {!accountData?.companyEmail ?
                                    <Group style={{cursor: 'pointer'}} noWrap spacing={6} onClick={()=> {
                                      hideNotification('accountInformationIncompleteError');        
                                      showNotification({
                                        message: <span>Die Absender-E-Mail ist unvollständig. Bitte ergänzen Sie die Kontaktdaten im <span style={{color: '#5555FF', cursor: 'pointer'}} onClick={()=> this.props.history.push('/profile/companyDetails')}>Nutzerkonto</span>.</span>,
                                        icon: <img src={RedCross} alt='red-cross' />,
                                        autoClose: false,
                                        id: 'accountInformationIncompleteError'
                                      })
                                    }}>
                                      <img src={RedCross} alt='red-cross'/>
                                      <span style={{color: '#dc3545', fontWeight: '500', fontSize: '12px', whiteSpace: 'nowrap'}}>Absender Email-Adresse fehlt</span>
                                    </Group>
                                    :
                                    <span style={{whiteSpace: 'nowrap'}}>{` ${accountData?.companyEmail}`}</span>}
                                  </span>
                                </span>}
                              </span>
                            </Group>
                          </Group>
                          
                          <span onClick={()=> this.props.history.push('/profile/companyDetails')} style={{fontSize: '12px', color: '#5555FF', cursor: 'pointer', fontWeight: 400, width: 'fit-content'}}>Kontaktdaten bearbeiten <img src={RightArrowPartner} style={{marginTop: '0px', width: '12px'}}/></span>
                        </Stack>}
                    </Stack>
                  </Group>
                  <Group>
                    {isMobile && this.renderAccountMessageBalanceAndEstimation()}
                  </Group>
                  <Stack spacing={2} style={{width: !isMobile && '560.5px', minWidth: isMobile ? '100%' : '300px'}}>
                    <lable>Betreff</lable>
                    <Group noWrap>
                      <div style={{width: '100%'}}>
                        <Editor
                        editorState={this.state.editorState}
                        toolbarClassName="message-subject-toolbar"
                        editorClassName="message-subject-editor"
                        id='message-subject-editor'
                        toolbar={{
                          inline: {
                            strikethrough: { className: 'rdw-option-hide' },
                            monospace: { className: 'rdw-option-hide' },
                            superscript: { className: 'rdw-option-hide' },
                            subscript: { className: 'rdw-option-hide' },
                          },
                          list: {
                            indent: { className: 'rdw-option-hide' },
                            outdent: { className: 'rdw-option-hide' },
                            className: 'rdw-option-hide'
                          },
                          fontSize: {className: 'rdw-option-hide'},
                          textAlign: {className: 'rdw-option-hide'}
                        }}
                        editorRef={this.setSubjectEditorRef}
                        onEditorStateChange={this.onEditorStateChange}
                        localization={{
                          locale: 'de',
                        }}
                        onFocus={()=> this.setState({subjectInFocus: true})}
                        onBlur={()=> setTimeout(() => {
                          this.handleAutoSave();
                        }, 600)}
                        toolbarOnFocus
                        />
                      </div>
                      {isMobile &&
                      <Popover
                      opened={messageSavingStatusPopper}
                      trapFocus={false}
                      position={'top'}
                      onFocusCapture={() => this.setState({messageSavingStatusPopper: true})}
                      onBlurCapture={() => this.setState({messageSavingStatusPopper: false})}
                      styles={{
                        body: {
                          background: 'rgb(230, 230, 255)'
                        },
                        inner: {
                          padding: '10px'
                        }
                      }}
                      target={
                        messageSavingLoader ?
                        <Loader onClick={()=> this.setState({messageSavingStatusPopper: !messageSavingStatusPopper})} size={'xs'} cursor={'pointer'}/>
                        :
                        <img src={BlueTick} onClick={()=> this.setState({messageSavingStatusPopper: !messageSavingStatusPopper})} style={{minWidth: '16px', cursor: 'pointer'}}/>
                      }>
                        {messageSavingLoader ?
                        <span style={{fontSize: '14px', fontWeight: 400, fontFamily: 'Inter, sans-serif', color: '#13135C', marginTop: '-2px'}}>speichern</span>
                        :
                        <span style={{fontSize: '14px', fontWeight: 400, fontFamily: 'Inter, sans-serif', color: '#13135C', marginTop: '-2px'}}>gesichert</span>}
                      </Popover>}
                    </Group>
                  </Stack>
                </Stack>
                {!isMobile && this.renderMessagingButtons()}
              </Group>
            </Stack>
            
          </Group>
          
          <MessagingEditor
          placeholders={placeholders}
          allRecipients={allRecipients}
          messageSavingLoader={messageSavingLoader}
          body={body}
          updateBody={(body)=> this.setState({messageData: {...this.state.messageData, body}},()=> this.handleAutoSave())}
          subjectInFocus={subjectInFocus}
          onFocus={()=> this.setState({subjectInFocus: false})}
          addPlaceHolderToSubject={(html)=> this.addPlaceHolderToSubject(html)}
          isMobile={isMobile}
          />
          {/* <MessagingEditorMantine/> */}
          
          {/* <Group style={{justifyContent: 'flex-end'}}>
              <div>
                  <ColorButton
                  text={'Send'}
                  className={'purple-btn'}
                  onClick={()=> null}
                  />
              </div>
          </Group> */}
        </Stack>
        {openRecipientsModal &&
        <AddMessagingRecipientsModal
        messageId={messageData.id}
        open={openRecipientsModal}
        tenants={tenants}
        messageType={messageType}
        showUtilityBillingMessages={showUtilityBillingMessages}
        toggle={()=> this.setState({openRecipientsModal: false},()=> this.getMessageRecipientsByMessageId(messageData.id))}
        previouslySelectedRecipients={allRecipients}
        getMessageRecipientsByMessageId={()=> this.getMessageRecipientsByMessageId(messageData.id)}
        setSelectedRecipients={(selectedRecipients)=> this.setState({selectedRecipients: selectedRecipients.sort((a,b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase())).map((recipient)=> `${recipient.tenantId}`), allRecipients: selectedRecipients})}
        isMobile={isMobile}
        disableRecipientSelection={messageData?.templateType === 'RentalPayment'}
        disableTenantsSelection={false}//messageData?.templateType === 'General'}
        disableExternalContactsAdd={(messageData?.templateType === 'Tenant' || messageData?.templateType === 'RentalPayment' || messageData?.templateType === 'BillingStatement')}
        />}
        {openMessagingPricingModal &&
        <MessagingPricingModal
        open={openMessagingPricingModal}
        history={this.props.history}
        paymentDetails={paymentDetails}
        toggle={()=> this.setState({openMessagingPricingModal: false})}
        putTopUpCredit={(amount)=> this.putTopUpCredit(amount)}
        isMobile={isMobile}
        />}
        {openPreviewModal && allRecipients &&
        <MessagePreviewModal
        open={openPreviewModal}
        multipleTenants={multipleTenants}
        toggle={()=> this.setState({openPreviewModal: false})}
        allRecipients={allRecipients}
        messageData={messageData}
        senderAddress={(!accountData?.addressCompany || !accountData?.addressStreet || !accountData?.addressZipCode || !accountData?.addressCity) ? <span style={{color: '#dc3545', fontWeight: '500'}}>Absenderadresse unvollständig</span> : ` ${accountData?.addressCompany+','} ${accountData?.addressStreet+','} ${accountData?.addressZipCode} ${accountData?.addressCity}`}
        senderEmail={accountData?.companyEmail}
        billingPeriod={billingPeriod}
        isMobile={isMobile}
        />}
        {openMessageConfirmationModal &&
        <MessageConfirmationModal
        open={openMessageConfirmationModal}
        postalFee={postalFee}
        allRecipients={allRecipients}
        onSend={()=> this.putPublishMessagesById()}//messageType === 'tenantPortalMessage' ? this.putPublishMessagesById() : messageData.templateType === 'RentalPayment' ? this.evaluateRentalPayments() : this.putPublishMessagesById()}
        toggle={()=> this.setState({openMessageConfirmationModal: false})}
        />}
        {openMessageDeletionModal &&
        <MessageDeleteModal
        open={openMessageDeletionModal}
        toggle={()=> this.setState({openMessageDeletionModal: false})}
        onDelete={()=> this.deleteMessagesById(messageData.id)}
        />}

        {openBulkMessageDownloadModal &&
        <BulkDownloadForMessageModal
        open={openBulkMessageDownloadModal}
        multipleTenants={multipleTenants}
        toggle={()=> this.setState({openBulkMessageDownloadModal: false})}
        allRecipients={allRecipients
        // tenants.map((tenant, index)=> ({
        //   ...tenant,
        //   id: index,
        //   tenantId: tenant.id,
        //   isEmailEnabled: checkProperty(tenant, 'email', false) ? true : false,
        //   isPostEnabled: (propertySelected.propertyAddress && propertySelected.propertyCity && propertySelected.propertyZipCode) ? true : false,
        //   isHiddenForTenantPortal: true,
        //   value: tenant.id,
        //   name: tenant.name,
        //   label: tenant.name
        // })).filter((value, index, self) =>
        // index === self.findIndex((t) => (
        // t.tenantId === value.tenantId
        // )))
        }
        messageData={messageData}
        senderAddress={(!accountData?.addressCompany || !accountData?.addressStreet || !accountData?.addressZipCode || !accountData?.addressCity) ? <span style={{color: '#dc3545', fontWeight: '500'}}>Absenderadresse unvollständig</span> : ` ${accountData?.addressCompany+','} ${accountData?.addressStreet+','} ${accountData?.addressZipCode} ${accountData?.addressCity}`}
        senderEmail={accountData?.companyEmail}
        billingPeriod={billingPeriod}
        isMobile={isMobile}
        />}
        <button id='publishMessage' style={{display: 'none'}}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentDataId: state.UtilityBillingReducer.currentBillingId,
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
    propertySelected: state.generalReducer.property,
    rentalUnits: state.tenantDataReducer.rentalUnits,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData,
    profile: state.generalReducer.profile,
  };
};

const mapDispatchToProps = {
  setUtilityBillingData,
  setCurrentBillingId,
  setPublishMessageId,
  setAccountData,
  setCurrentMessagesData
};

export default connect(mapStateToProps, mapDispatchToProps)(NewMessageInnerContent);
